import './index.scss';
import './mobile.scss';
import React from 'react';

import Markdown from 'markdown-to-jsx';

import NavBar from '../../Components/navbar/SignedOut';
import { Footer } from '../../Components/Footer';


export const About : React.FC = () => {
    return (
        <div className='about-page'>
            <NavBar />

            <div className="content">
                <div className='team-body'>
                    <div className='title'>TEAM</div>

                    <div className='container'>
                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Chidozie Nnaji</div>
                                <div className='title'>CEO & CTO</div>
                            </div>   
                        </div>

                        <div className='seperator' />

                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Darian Brown</div>
                                <div className='title'>Chief Opperations Officer</div>
                            </div>   
                        </div>

                        <div className='seperator' />

                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Nathan Vo</div>
                                <div className='title'>CMO & Outreach Officer</div>
                            </div>   
                        </div>

                        <div className='seperator' />

                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Ardel Mkwe</div>
                                <div className='title'>Outreach Officer</div>
                            </div>   
                        </div>

                        <div className='seperator' />

                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Andrew Jackson</div>
                                <div className='title'>Chief Event Cordinator</div>
                            </div>   
                        </div>

                        <div className='seperator' />

                        <div className='team-member'>
                            <img src='/cravings-logo.png' alt='member' />

                            <div className='details'>        
                                <div className='name'>Christian Nweobu</div>
                                <div className='title'>Tech Intern</div>
                            </div>   
                        </div>
                    </div>
                </div>

                <div className='body'>
                    <div className='header'>
                        <div className='buldge' />

                        <div className='title'>About</div>
                        
                        {
                            // Goes to terms of service page 
                        }
                        <div className='button'>TOS</div>
                    </div>

                    <div className='content'>
                        <div className='container'>
                            <div className='title'>How We Started</div>

                            <div className='text-container'>
                                <div className='buldge' />

                                <div className='text'>
                                    <Markdown>{`CravingsInc began in June 2023 as a call service dedicated to connecting food trucks with events and event organizers, earning a 10% fee for our services. Our breakthrough came when we partnered with the Detroit Pistons Youth Council to bring "A Taste Of Motown" food truck to their "Family Fun Day Event." This successful collaboration opened doors to numerous other organizers, establishing us as a reliable source for integrating food trucks into various events.`}</Markdown>
                                </div>
                            </div>
                        </div>

                        <div className='container'>
                            <div className='title'>Who We Are Now</div>

                            <div className='text-container'>
                                <div className='buldge' />

                                <div className='text'>
                                    <Markdown>{`Today, CravingsInc has evolved into a comprehensive platform that empowers event organizers to sell tickets directly to users while offering a robust marketplace for sourcing essential event services. From venues and food trucks to catering and tent setups, our platform provides organizers with the tools they need to plan, execute, and market their events effectively. We bridge the gap between organizers and service providers, ensuring seamless and successful events.`}</Markdown>
                                </div>
                            </div>
                        </div>

                        <div className='container'>
                            <div className='title'>What We Offer</div>

                            <div className='text-container'>
                                <div className='buldge' />

                                <div className='text'>
                                    <Markdown>{`CravingsInc offers a versatile and dedicated organizer platform for planning, selling, and marketing events. While also providing a comprehensive marketplace for venues to connect with customers and manage their operations. Our suite of services is designed to streamline the event planning process, providing everything from ticket sales to finding the perfect food truck. Additionally, we take pride in planning and hosting our own events, showcasing the capabilities of our platform.`}</Markdown>
                                </div>
                            </div>
                        </div>

                        <div className='container'>
                            <div className='title'>Future Plans</div>

                            <div className='text-container'>
                                <div className='buldge' />

                                <div className='text'>
                                    <Markdown>{`Our future vision for CravingsInc includes a fully functioning marketplace and a mobile app, positioning us as the premier destination for discovering and planning events. We aim to be the go-to platform for anyone looking to create, manage, or attend events, ensuring that every experience is memorable and well-executed. By continually expanding our services and capabilities, we strive to meet all the event cravings of our diverse user base.`}</Markdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
