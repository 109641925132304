import React from 'react';

import Rating from 'react-star-rating-component';

import EventReviewCard, { EventReviewCardProps } from '../../../Components/EventReviewCard';

type ReviewViewProps = {
    reviews: EventReviewCardProps[];
}

export const ReviewView: React.FC<ReviewViewProps> = ({ reviews }) => {
    let average = reviews.length > 0  ? Math.ceil( reviews.map( r => r.rating ).reduce( ( acc, num ) => acc + num, 0 ) / reviews.length ) : 0;
    return reviews.length > 0 ? (
        <div className='reviews'>
            <div className='review-summary'>
                <div className='rating'>{ average }.0</div>

                <div className='star-wrapper'>
                    <Rating
                        name='rate'
                        editing={false}
                        value={average}
                    />
                </div>

                <div className='context'>Based On { reviews.length } Reviews</div>
            </div>

            <div className='content'>
                { reviews.map( r => <EventReviewCard { ...r } /> )}
            </div>
        </div>
    ) : <div className='empty'>No Reviews Has Been Posted For Event</div>
}
