import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Craving, LOCAL_STORAGE_NAMESPACES, createOrgAccount, createUserAccount, fetchGraphQl } from '../../utils';

export const useRegister = () => {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ created, setCreated ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>("");
    const [ token, setToken ] = useState<string | null>(null);

    /**
     * Register user or organizer, and then saves the token and navigates to home if nav is true.
     * @param username username or organizer name
     * @param email email address for organizer or user
     * @param password password for organizer or user
     * @param craving either a ticket or event, ticket for organizer and event for user
     * @param nav whether or not to go to home page, and save token to local storage
     * @param alarm whether or not to alert when error is encountered
     * @param afterEffects what to do after function is done running
     * @returns 
     */
    const register = async ( username: string, email: string, password: string, craving: Craving, nav: boolean = true, alarm: boolean = true, afterEffect?: () => void ) => {
        setLoading( true );

        let res = await fetchGraphQl( craving === 'ticket' ? createOrgAccount : createUserAccount, { 
            [ craving === 'ticket' ? 'orgName' : 'username']: username, email, password
        } );

        if ( res.errors ) {
            setCreated( false );
            setLoading( false );
            setError( res.errors[0].message );

            return alert( res.errors[0].message );
        }

        let serverToken: string = res.data[ craving === 'ticket' ? 'CreateOrganizerAccount' : 'CreateUserAccount'];

        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, serverToken );
        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.CRAVING, craving );

        setToken( serverToken );
        setCreated( true );
        setLoading( false );
        
        if ( afterEffect ) afterEffect();
        if ( nav ) navigate('/home');
    }

    return {
        loading,
        created,
        error,
        token,
        register
    }
}
