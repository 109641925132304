import React, { useEffect, useState } from 'react';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import DownArrow from '../svgs/DownArrow';
import { getDomainUrl, isMobileDevice } from '../../utils';

type CheckOutFormProps = {
    update: boolean;
    eventId: string;
    userToken?: string;
    checkOut: { quantity: number, title: string | undefined, price: number | undefined}[]
    changeUpdate: () => void;
    mobileCounter?: number;
    back?: () => void;
}

const CheckoutForm: React.FC<CheckOutFormProps> = ({ eventId, update, changeUpdate, userToken, checkOut, mobileCounter, back }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string>();

    const [ slideView, setSlideView ] = useState<{ checkout: boolean; contact: boolean; payment: boolean }>({ checkout: true, contact: false, payment: false });

    const [ userInfo, setUserInfo ] = useState<{ name: string, email: string, phoneNumber: string }>({ name: '', email: '', phoneNumber: '' })

    const [ count, setCount ] = useState<number>(0);

    useEffect( () => {
        if ( update ) {
            (
                async () => {
                    const updated = await elements?.fetchUpdates()
                    changeUpdate();
                }
            )()
        }
    }, [ update ])

    const onClick = async ( e: any ) => {
        e.preventDefault();

        if ( !stripe || !elements ) return;

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${getDomainUrl()}events/${eventId}/ticket`,
                payment_method_data: {
                    billing_details: {
                        email: userInfo.email,
                        name: userInfo.name,
                        phone: userInfo.phoneNumber
                    }
                },
                
            }
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    const backController = () => {
        if ( isMobileDevice() ) {
            if ( count - 1 === -1 ) back && back();
            else setCount(count - 1);
        }else back && back();
    }

    const nextController = () => {
        if ( count < 2 ) setCount( count + 1 );
        else setCount( 2 );
    }

    return (
        <div className='check-out-form'>
            <div className='content-container'>
                {
                    ( isMobileDevice() && count === 0 ) || !isMobileDevice() ? (
                        <div className={`slide ${ slideView.checkout || ( isMobileDevice() && count === 0 ) ? 'active' : 'inactive'}`}>
                            <div className='header' onClick={ () => setSlideView( prev => ({ ...prev, checkout: !prev.checkout }) ) }>
                                <div className='title'>Checkout</div>
                                <DownArrow fill='black' />
                            </div>

                            <div className="content checkout">
                                {
                                    checkOut.map( cItem => (<div className='content-item'>{cItem.quantity}x {cItem.title} ${cItem.price}</div>))
                                }
                            </div>
                        </div>
                    ) : undefined
                }

                {
                    !userToken && ( ( isMobileDevice() && count === 1 ) || !isMobileDevice() ) && (
                        <div className={`slide ${ slideView.contact || ( isMobileDevice() && count === 1 ) ? 'active' : 'inactive'}`}>
                            <div className='header' onClick={ () => setSlideView( prev => ({ ...prev, contact: !prev.contact }) ) }>
                                <div className='title'>Contact Form</div>
                                <DownArrow fill='black' />
                            </div>

                            <div className="content">
                                <div className='form-group'>
                                    <input 
                                        type='text' placeholder='Name'
                                        onChange={ (e) => setUserInfo( prev => ({ ...prev, name: e.target.value }) ) }
                                    />

                                    <input 
                                        type='email' placeholder='Email' 
                                        onChange={ (e) => setUserInfo( prev => ({ ...prev, email: e.target.value }) ) }
                                    />

                                    <input 
                                        type='telephone' placeholder='Phone Number' 
                                        onChange={ (e) => setUserInfo( prev => ({ ...prev, phoneNumber: e.target.value }) ) }
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    ( isMobileDevice() && count === 2 ) || !isMobileDevice() ? ( 
                        <div className={`slide ${ slideView.payment || ( isMobileDevice() && count === 2 ) ? 'active' : 'inactive'}`}>
                            <div className='header' onClick={ () => setSlideView( prev => ({ ...prev, payment: !prev.payment }) ) }>
                                <div className='title'>Payment</div>
                                <DownArrow fill='black' />
                            </div>

                            <div className="content">
                                <PaymentElement />
                            </div>
                        </div>
                    ) : undefined
                }
            </div>
            <div className='button-container'>
                { mobileCounter === 1 && <div className='button' onClick={backController}>Back</div> }
                { isMobileDevice() && count <= 1 && <div className='button' onClick={nextController}>Next</div>}
                { ( isMobileDevice() && count >= 2 ) || !isMobileDevice() ? <div className='button' onClick={onClick}>Submit</div> : undefined }
            </div>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </div>
    )
}

export default CheckoutForm;
