import './index.scss';

import React from 'react';
import { PublicInfo } from './PublicInfo';
import { PrivateInfo } from './PrivateInfo';
import { SearchMileRadius } from './SearchMileRadius';
import { RequestPasswordChange } from './RequestPasswordChange';
import { Notifications } from './Notifications';

import { useSettings } from '../../../hooks';

type SettingsProps = {
    alertPopUp: ( val: string ) => void;
};

const Settings: React.FC<SettingsProps> = ({ alertPopUp }) => {
    const { token, startingInfo, publicInfo, privateInfo, searchMileRadius, passwordChange, notificationsSettings, notificationsText, saveNotificationsSettings, setUpdtesNotification, setNewFollowerNotification } = useSettings({ alertPopUp });


    return (
        <div className='settings'>
            <PublicInfo
                token={token as string}

                username={startingInfo.username}
                setUserName={publicInfo.setUserName}

                email={startingInfo.email}
                setEmail={publicInfo.setEmail}

                profilePicture={startingInfo.profilePicture}
                setProfilePicture={publicInfo.setProfilePicture}
            
                saveAllChanges={publicInfo.savePublicChanges}
                saveChangesText={publicInfo.savePublicChangesText}

                alertPopUp={alertPopUp}
            />

            <PrivateInfo

                firstName={startingInfo.firstName}
                setFirstName={privateInfo.setFirstName}

                lastName={startingInfo.lastName}
                setLastName={privateInfo.setLastName}

                phoneNumber={startingInfo.phoneNumber}
                setPhoneNumber={privateInfo.setPhoneNumber}

                saveAllChanges={privateInfo.savePrivateChanges}
                saveChangesText={privateInfo.savePrivateChangesText}

                alertPopUp={alertPopUp}
            />

            <SearchMileRadius
                searchMileRadius={searchMileRadius.val}
                saveSearchMileRadius={searchMileRadius.save}
            />

            <RequestPasswordChange
                request={passwordChange.request}
                requestText={passwordChange.text}
            />

            <Notifications
                updates={notificationsSettings.updates}
                setUpdates={setUpdtesNotification}

                newFollower={notificationsSettings.newFollower}
                setNewFollower={setNewFollowerNotification}

                save={saveNotificationsSettings}
                saveText={notificationsText}
            />
        </div>
    )
}

export default Settings;
