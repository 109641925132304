import "./index.scss";
import "./mobile.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import Markdown from 'markdown-to-jsx';

import { useEvents } from "../../../hooks";
import { formatDate, truncateText } from "../../../utils";

import { isMobile } from 'react-device-detect';

import SignedOutNavBar from "../../../Components/navbar/SignedOut";
import SignedInNavBar from "../../../Components/navbar/SignedIn";
import DownArrow from "../../../Components/svgs/DownArrow";

import { Footer } from "../../../Components/Footer";
import { EventsCard } from '../../../Components/EventCard';

export const Events: React.FC<any> = () => {
    const navigate = useNavigate();

    const { user, loggedIn, tokenType, currentFeaturedEvent, backFeaturedEvent, forwardFeaturedEvent, upComingEvents, popularEvents, popularOrganizerEvents, favoriteOrganizerEvents, eventsFriendsGoingTo } = useEvents();

    return (
        <div className="EventsPage">
            {
                user ? <SignedInNavBar profilePicture={user.profilePicture} /> 
                : <SignedOutNavBar />
            }
            
            <div className="eventContents">
                {
                    currentFeaturedEvent && (
                        <div className='featured-event container'>
                            <div className='title'>Featured Event</div>

                            <div className='slide'>
                                <DownArrow className="left" onClick={backFeaturedEvent}/>

                                <div className="content">
                                    <img src={currentFeaturedEvent.banner} alt='event-banner' className='event-banner' />

                                    <div className="info">
                                        <div className='title-cost-date'>
                                            <div className="title">{currentFeaturedEvent.title}</div>
                                        
                                            <div className='loc-url'>
                                                <div className="date cost-range">{formatDate(new Date( currentFeaturedEvent.eventDate ))}</div>

                                                <div className='bullet' />

                                                <div className='cost-range'> {currentFeaturedEvent.costRange} </div>
                                            </div>
                                        </div>

                                        <div className='desc'>
                                            <Markdown>
                                                {truncateText(currentFeaturedEvent.description, 250)}
                                            </Markdown>
                                        </div>

                                        <div className='button' onClick={ () => navigate(`/events/${currentFeaturedEvent.id}`) }>More Info</div>
                                    </div>
                                </div>

                                <DownArrow className="right" onClick={forwardFeaturedEvent} />
                            </div>
                        </div>
                    )
                }

                <div className='container'>
                    <div className='title'>UPCOMING EVENTS</div>
                    <div className='container-events'>
                        {
                            upComingEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        upComingEvents.length === 0 && (
                            <div className='no-content'>No Current Events Available</div>
                        )
                    }
                </div>

                <div className='container'>
                    <div className='title'>POPULAR EVENTS</div>
                    <div className='container-events'>
                        {
                            popularEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        popularEvents.length === 0 && (
                            <div className='no-content'>No Current Events Available</div>
                        )
                    }
                </div>

                <div className='container'>
                    <div className='title'>POPULAR ORGANIZERS EVENTS</div>
                    <div className='container-events'>
                        {
                            popularOrganizerEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                        }
                    </div>

                    {
                        popularOrganizerEvents.length === 0 && (
                            <div className='no-content'>No Current Events Available</div>
                        )
                    }
                </div>

                {
                    loggedIn && tokenType === 'events' && (
                        <div className='container'>
                            <div className='title'>EVENTS BY ORGANIZERS FOLLOWING</div>
                            <div className='container-events'>
                                {
                                    favoriteOrganizerEvents.map( (e, index) => <EventsCard key={index} {...e} /> )
                                }
                            </div>

                            {
                                favoriteOrganizerEvents.length === 0 && (
                                    <div className='no-content'>No Current Events Available</div>
                                )
                            }
                        </div>
                    )
                }
 
                {
                    loggedIn && tokenType === 'events' && (
                        <div className='container'>
                            <div className='title'>EVENTS FRIENDS ARE GOING TO</div>
                            <div className='container-events'>
                                {
                                    eventsFriendsGoingTo.map( (e, index) => <EventsCard key={index} {...e} /> )
                                }
                            </div>

                            {
                                eventsFriendsGoingTo.length === 0 && (
                                    <div className='no-content'>No Current Events Available</div>
                                )
                            }
                        </div>
                    )
                }
            </div>

            <Footer />
        </div>
    )
}
