import './index.scss';
import './mobile.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogin } from '../../../hooks';

import { Craving, getOrganizerUrl } from '../../../utils';


type LoginProps = {
    craving: Craving;
}

const Login: React.FC<LoginProps> = ({ craving }) => {
    const navigate = useNavigate();

    const [ { email, password }, setState ] = useState<{ email: string, password: string }>({ email: '', password: '' });
    const { loading, login } = useLogin( craving );

    useEffect( () => {
        if ( craving === 'ticket' ) window.location.href = `${getOrganizerUrl()}sign-up`
    }, [ craving ]);

    return craving === 'ticket' ? <></> : (
        <div className='banner login'>
            <div className='title'>Login</div>

            <div className='input-group'>
                <input 
                    type='email' className='form-control' 
                    placeholder={'Email'} 
                    onChange={ (e) => setState( prev => ({ ...prev, email: e.target.value }) ) }
                />
                <input 
                    type='password' className='form-control' 
                    placeholder={'Password'} 
                    onChange={ (e) => setState( prev => ({ ...prev, password: e.target.value }) ) }
                />
            </div>

            <div className='button' style={{ pointerEvents: loading ? 'none': undefined }} onClick={ () => login( email, password, craving )}>
                {
                    !loading ? 'Login' : 'Logining...'
                }
            </div>

            <div className='login-text' onClick={ () => navigate(`/register?craving=${craving}`)}>
                Don't have an account? Register
            </div>
        </div>
    )
}

export default Login;
