import './index.scss';
import './mobile.scss';

import React, { useState, useEffect } from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';

import { EventPricesResponse, createTicketSellClientSecret, fetchGraphQl, getDomainUrl, getServerUrl, updateTicketSellClientSecret } from '../../utils';
import EventQrCode from '../EventQrCode';

type PurchaseEventTicketProps = {
    tickets: EventPricesResponse[];
    eventId: string;
    userToken?: string;
    active: boolean;
    clickCount: number;
    ticketType: 'infinite' | 'limited';
    disable: () => void;
}

const stripePromise = loadStripe( process.env.REACT_APP_STRIPE as string );

const PurchaseEventTicket: React.FC<PurchaseEventTicketProps> = ({ tickets, active, disable, eventId, userToken, clickCount, ticketType }) => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();

    const [ clientSecret, setClientSecret ] = useState<string>('{{CLIENT_SECRET}}');
    const [ paymentIntentId, setPaymentIntentId ] = useState<string>('{{PAYMENT_INTENT_ID}}');

    const [ ticketBuy, setTicketBuy ] = useState<{ id: string; quantity: number }[]>([]);

    const [ update, setUpdate ] = useState<boolean>( false );

    const [ mobileCounter, setMobileCounter ] = useState<number>(0);

    const getCheckOut = () => {
        return ticketBuy.map( tB => {
            const ticket = tickets.find( t => t.id === tB.id );

            return { quantity: tB.quantity, title: ticket?.title, price: ticket?.amount }
        })
    }

    const subtractQuantity = ( id: string ) => {
        if ( ticketBuy.findIndex( p => p.id === id ) !== -1 ) {
            setTicketBuy( prevState => {
                return prevState.map( ticket => {
                    if ( ticket.id === id && ticket.quantity > 0 ) {
                        return { ...ticket, quantity: ticket.quantity - 1 }
                    }
    
                    return ticket;
                })
            });
        }else {
            setTicketBuy( prev => ([...prev, { id, quantity: 0 } ] ) );
        }
    }

    const addQuantity = ( id: string ) => {
        if ( ticketBuy.findIndex( p => p.id === id ) !== -1 ) {
            let foundTicket = tickets.findIndex( p => p.id === id );

            setTicketBuy( prevState => {
                return prevState.map( ticket => {
                    if ( ticket.id === id && ( ticket.quantity < tickets[ foundTicket ].ticketAvailable || ticketType === 'infinite' )) {
                        return { ...ticket, quantity: ticket.quantity + 1 }
                    }
    
                    return ticket;
                })
            });
        }else {
            setTicketBuy( prev => ([...prev, { id, quantity: 1 } ] ) );
        }
    }

    const getQuantity = ( id : string ) => {
        const ticket = ticketBuy.find( ticket => ticket.id === id );

        return ticket ? ticket.quantity : 0;
    }

    useEffect( () => {
        const bootLoader = async () => {
            setLoading( true );
            const res = await fetchGraphQl(createTicketSellClientSecret, { eventId, userToken });

            if ( res.errors ) {
                console.log( res.errors );
                setError( res.errors[0].message );
                return;
            }

            setClientSecret( res.data.createTicketSellClientSecret );

            setPaymentIntentId( ( await ( await stripePromise )?.retrievePaymentIntent( res.data.createTicketSellClientSecret ) )?.paymentIntent?.id || '')

            setLoading( false );
        }

        if ( clickCount === 1 ) bootLoader();
    }, [ clickCount ])

    useEffect(() => {

        let runner = async () => {
            const res = await fetchGraphQl(updateTicketSellClientSecret, { id: paymentIntentId, eventId, prices: ticketBuy });

            if ( res.errors ) return console.log( res.errors );

            if ( res.data.updateTicketSellClientSecret === 'requires_payment_method' ) {
                setUpdate(true);
            }
        }

        runner();
    }, [ticketBuy]);    

    const options = {
        clientSecret,
        layout: 'tabs',
        fields: {
            billingDetails: {
                name: 'auto',
                email: 'auto',
                phone: 'auto',
                address: 'never'
            }
        },
        appearance: {
            theme: 'night',
            labels: 'floating',
            variables: {
                fontFamily: "Expletus Sans"
            }
        }
    } 

    return (
        <div className='purchase-event-ticket' style={{ display: active ? undefined : 'none' }}>
            <div className='shade' onClick={ () => disable() } />

            <div className='next-buttons-container' style={ mobileCounter === 1 ? { display : 'none' } : undefined }>
                {
                    mobileCounter != 0 && <div className='mobile-next-button back' onClick={ ( ) => setMobileCounter( mobileCounter - 1 ) }>BACK</div>
                }
                
                <div className='mobile-next-button' onClick={ ( ) => setMobileCounter( mobileCounter + 1 ) }>NEXT</div>
            </div>

            <div className='check-out'>
                <div className={`tickets-container ${ mobileCounter != 0 ? 'disable' : '' }`}>

                    {
                        tickets.map( (p, index ) => (
                            <div className='ticket' key={`ticket-${index}`}>
                                <div className='title-amount'>
                                    <div className='title-count'>
                                        <div className='title'>{p.title}</div>
                                        <div className='count'>
                                            <div className='minus' onClick={ () => subtractQuantity(p.id) }>-</div>
                                            <div className='num'>{ getQuantity(p.id) }</div>
                                            <div className='plus' onClick={ () => addQuantity(p.id) }>+</div>
                                        </div>
                                    </div>

                                    <div className='cost-limit'>
                                        <div className='cost'>${p.amount}</div>
                                        {
                                            ticketType === 'infinite' ? <></>
                                                :
                                                p.ticketAvailable > 0 ? <div className='stats'>{ p.ticketAvailable } Tickets Available</div>
                                                : <div className='stats'>SOLD OUT</div>
                                        }
                                    </div>

                                    <div className='seperator' />
                                </div>

                                <div className='desc'>{p.description}</div>
                            </div>
                        ))
                    }

                </div>

                <div className={`check-out-container ${ mobileCounter === 1 ? '' : 'disable'}`}>
                    {
                        loading || error ? <></> : (
                            <Elements stripe={stripePromise} options={options as any}>
                                <CheckoutForm eventId={eventId} userToken={userToken} update={update} changeUpdate={ () => setUpdate(false) } checkOut={getCheckOut()} mobileCounter={mobileCounter}  back={ ( ) => setMobileCounter( mobileCounter - 1 )} />
                            </Elements>
                        )
                    }
                </div>
            </div>

            <EventQrCode data={`${getDomainUrl()}events/${eventId}/ticket?payment_intent=${paymentIntentId}`} image="/logo-text.png" display={true} upload={{ link: `${getServerUrl()}api/cart/QrCode`, data: { payment_intent: paymentIntentId } }} />
        </div>
    )
}

export default PurchaseEventTicket;
