import React, { useState, useEffect } from 'react';

import { useLogin } from '../../session';

import { UserNotificationsSettings, UserProfileSettings, changeUserPassword, fetchGraphQl, getUserProfileInformation, modifyUserNotifications, modifyUserProfileInformationQuery } from '../../../utils';

type PublicInfo = {
    username: string;
    email: string;
    profilePicture: string;
}

type PrivateInfo = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
}


type Props = {
    alertPopUp: ( val: string ) => void;
};

export const useSettings = ({ alertPopUp }: Props ) => {
    const { token, verifyLogin } = useLogin();

    const [ startingInfo, setStartingInfo ] = useState< PublicInfo & PrivateInfo >({ 
        username: '',
        email: '',
        profilePicture: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
    });

    const [ publicInfo, setPublicInfo ] = useState< { saveLoading: number } & PublicInfo>({ username: '', email: '', profilePicture: '', saveLoading: 0 });

    const [ privateInfo, setPrivateInfo ] = useState<{ saveLoading: number } & PrivateInfo>({ firstName: '', lastName: '', phoneNumber: '', saveLoading: 0 });
    
    const [ searchMileRadius, setSearchMileRadius ] = useState<number>(12);

    const [ requestPassWordText, setRequestPasswordText ] = useState<string>('Request');

    const [ notificationsSettings, setNotificationsSettings ] = useState<UserNotificationsSettings>({
        updates: false,
        newFollower: false
    });

    const [ notificationsText, setNotificationsText ] = useState<string>('Save');

    const [ error, setError ] = useState<string>('');

    useEffect( () => {
        verifyLogin(true);
    }, [ ]);

    useEffect( () => {
        (
            async () => {
                if ( !token ) return;

                const res = await fetchGraphQl( getUserProfileInformation, { token });

                if ( res.errors ) {
                    console.log( res.errors );
                    return setError( res.errors[0].message );
                }

                let data = res.data.getUserProfileInformation as UserProfileSettings;

                setStartingInfo({ 
                    username: data.username, email: data.email, profilePicture: data.profilePicture,
                    firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber
                });

                setSearchMileRadius(data.searchMilesRadius);

                setNotificationsSettings( data.notificationsSettings );
            }
        )()
    }, [ token ]);

    useEffect( () => {
        (
            async () => {
                if ( !token ) return;

                let arg = {
                    searchMilesRadius: searchMileRadius
                }
        
                const res = await fetchGraphQl( modifyUserProfileInformationQuery, { arg, token });
        
                if ( res.errors ) {
                    console.log( res.errors );
                    return setError( res.errors[0].message );
                }
            }
        )();
    }, [ searchMileRadius ]);

    useEffect( () => {
        error.length > 0 && alertPopUp( error || '' );
    }, [ error ]);

    return {
        token,

        startingInfo,

        publicInfo: {
            savePublicChanges: async () => {
                // Means a save function is already registered, don't do anything so won't run twice.
                if ( publicInfo.saveLoading === 1 || !token ) return;

                setPublicInfo( prev => ({ ...prev, saveLoading: 1 }));

                let arg = {
                    username: publicInfo.username.length > 0 ? publicInfo.username: undefined,
                    email: publicInfo.email.length > 0 ? publicInfo.email : undefined,
                    profilePicture: publicInfo.profilePicture.length > 0 ? publicInfo.profilePicture : undefined
                }

                const res = await fetchGraphQl( modifyUserProfileInformationQuery, { arg, token })
            
                if ( res.errors ) {
                    console.log( res.errors );
                    setPublicInfo({ username: '', email: '', profilePicture: '', saveLoading: 0 });
                    return setError( res.errors[0].message ); // Will launch alert pop up.
                }

                setPublicInfo( prev => ({ ...prev, saveLoading: 2 }));

                setTimeout(() => {
                    setPublicInfo({ username: '', email: '', profilePicture: '', saveLoading: 0 });
                }, 5000);
            },

            savePublicChangesText: publicInfo.saveLoading === 1 ? 'Saving...' : 
                publicInfo.saveLoading === 2 ? 'Saved successfully' : 'Save Changes',

            setUserName: ( val : string ) => setPublicInfo( prev => ({ ...prev, username: val }) ),
            setEmail: ( val : string ) => setPublicInfo( prev => ({ ...prev, email: val }) ),
            setProfilePicture: ( val : string ) => setPublicInfo( prev => ({ ...prev, profilePicture: val }) )
        },

        privateInfo: {
            savePrivateChanges: async () => {
                if ( privateInfo.saveLoading === 1 || !token) return;
                
                setPrivateInfo( prev => ({ ...prev, saveLoading: 1 }));

                let arg = {
                    firstName: privateInfo.firstName.length > 0 ? privateInfo.firstName : undefined,
                    lastName: privateInfo.lastName.length > 0 ? privateInfo.lastName : undefined,
                    phoneNumber: privateInfo.phoneNumber.length > 0 ? privateInfo.phoneNumber : undefined
                }

                const res = await fetchGraphQl( modifyUserProfileInformationQuery, { arg, token });

                if ( res.errors ) {
                    console.log( res.errors );
                    setPrivateInfo({ firstName: '', lastName: '', phoneNumber: '', saveLoading: 0  });
                    return setError( res.errors[0].message );
                }

                setPrivateInfo( prev => ({ ...prev, saveLoading: 2 }));

                setTimeout(() => {
                    setPrivateInfo({ firstName: '', lastName: '', phoneNumber: '', saveLoading: 0  });
                }, 5000);
            },

            savePrivateChangesText: privateInfo.saveLoading === 1 ? 'Saving...' : 
                privateInfo.saveLoading === 2 ? 'Saved successfully' : 'Save Changes',

            setFirstName: ( val: string ) => setPrivateInfo( prev => ({ ...prev, firstName: val })),
            setLastName: ( val: string ) => setPrivateInfo( prev => ({ ...prev, lastName: val })),
            setPhoneNumber: ( val: string ) => setPrivateInfo( prev => ({ ...prev, phoneNumber: val }))
        },

        searchMileRadius: {
            val: searchMileRadius,
            save: ( val: number ) => setSearchMileRadius(val)
        },

        passwordChange: {
            text: requestPassWordText,
            request: async () => {
                if ( !token ) return;

                setRequestPasswordText('Requesting....');

                const res = await fetchGraphQl( changeUserPassword, { token });

                if ( res.errors ) {
                    console.log( res.errors );
                    
                    setRequestPasswordText('Problem requesting password change');

                    setTimeout(() => {
                        setRequestPasswordText('Request');
                    }, 5000);
                    
                    return setError( res.errors[0].message );
                }

                setRequestPasswordText('Requested');

                setTimeout(() => {
                    setRequestPasswordText('Request');
                }, 5000);
            }
        },

        notificationsSettings,

        setUpdtesNotification: ( val: boolean ) => setNotificationsSettings( prev => ({ ...prev, updates: val })),
        setNewFollowerNotification: ( val: boolean ) => setNotificationsSettings( prev => ({ ...prev, newFollower: val })),

        notificationsText,

        saveNotificationsSettings: async () => {
            setNotificationsText('Saving...');

            let res = await fetchGraphQl( modifyUserNotifications, { arg: notificationsSettings, token })

            if ( res.errors ) {
                console.log( res.errors );

                setNotificationsText("Error");

                setTimeout( () => {
                    setNotificationsText("Save");
                }, 5000);

                return setError( res.errors[0].message );
            }

            setNotificationsText('Saved');

            setTimeout( () => {
                setNotificationsText('Save');
            }, 5000);
        }
    }
}
