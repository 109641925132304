export const getOrganizerProfile = `
query GetOrganizerProfile($token: String!) {
    getOrganizerProfile(token: $token) {
      id
      latitude
      location
      longitude
      orgName
      phoneNumber
      profilePicture
      updatedDate
      email
      createdDate
      banner
    }
}
`;
