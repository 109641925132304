export const getApiUrl = () => {
    return `${getServerUrl()}graphql`;
}

export const getWSApiUrl = () => {
    return  `wss://${process.env.NODE_ENV === "production" ? "server.cravingsinc.us" : "localhost:3555"}/graphql`;
}

export const getImageUploadApi = () => {
    return `${getServerUrl()}upload/image`;
}

export const getReviewProfileUploadApi = () => `${getServerUrl()}api/cart/review/picture`

export const getServerUrl = () => {
    return process.env.NODE_ENV === "production" ? "https://server.cravingsinc.us/" : "http://localhost:3555/";
}

export const getDomainUrl = () => window.location.origin + '/';

export const getOrganizerUrl = () => {
    return process.env.NODE_ENV === "production" ? "https://organizer.cravingsinc.us/" : "http://localhost:5000/"
}
