export const verifyUserPasswordChangeToken = `
query Query($token: String!) {
    verifyUserPasswordChangeToken(token: $token)
}
`;

export const confirmChangePassword = `
mutation confirmUserPasswordChange($token: String!, $newPassword: String!, $confirmNewPassword: String!) {
    confirmUserPasswordChange(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, token: $token)
}
`
