import './index.scss';
import './mobile.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRegister } from '../../../hooks';

import { Craving, getOrganizerUrl } from '../../../utils';

type CreateAccountProps = {
    craving: Craving;
    nav?: boolean;
    afterEffect?: () => void;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ craving, nav, afterEffect }) => {
    const navigate = useNavigate();

    const [ { username, email, password }, setState ] = useState<{ username: string, email: string, password: string }>({ username: '', email: '', password: '' });
    const { loading, register } = useRegister();

    useEffect( () => {
        if ( craving === 'ticket' ) window.location.href = `${getOrganizerUrl()}register`
    }, [ craving ]);

    return craving === 'ticket' ? < ></> : (
        <div className='banner create-account'>
            <div className='title'>Create Account</div>

            <div className='input-group'>
                <input 
                    type='text' className='form-control'
                    placeholder={`User Name`} 
                    onChange={ (e) => setState( prev => ({ ...prev, username: e.target.value }) ) }
                />
                <input 
                    type='email' className='form-control' 
                    placeholder={'Email'} 
                    onChange={ (e) => setState( prev => ({ ...prev, email: e.target.value }) ) }
                />
                <input 
                    type='password' className='form-control' 
                    placeholder={'Password'} 
                    onChange={ (e) => setState( prev => ({ ...prev, password: e.target.value }) ) }
                />
            </div>

            <div className='button' style={{ pointerEvents: loading ? 'none': undefined }} onClick={ () => register( username, email, password, craving, nav, false, afterEffect )}>
                {
                    !loading ? 'Create' : 'Creating...'
                }
            </div>

            <div className='login-text' onClick={ () => navigate(`/sign-up?craving=${craving}`)}>
                Already have an account? Sign In
            </div>
        </div>
    )
}

export default CreateAccount;
