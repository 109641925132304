import './index.scss';

import React, { useEffect } from 'react';

import { Alert, PopUpAlertProps } from './Alert';

type PopUpProps = {
    disableTimer?: boolean;
    disableLength?: number;
    activate: boolean;
    disablePopUp: () => void;
}

export type AlertProps = {
    type: 'alert';
    alert: PopUpAlertProps;
}

const PopUp: React.FC<PopUpProps & ( AlertProps ) > = ({ 
    disableTimer, disableLength, activate, disablePopUp, 
    type, alert
}) => {
    const backgroundColor = type === 'alert' ? 'transparent' : 'black';

    useEffect( () => {
        if ( disableTimer ) {
            const timeoutId = setTimeout( () => {
                disablePopUp();
            }, disableLength! * 1000 );

            return () => {
                clearTimeout( timeoutId );
            }
        }
    }, [ activate ]);

    return !activate ? <></> :  (
        <div className='popup-container'>
            <div className='popup-background' style={{ backgroundColor }} onClick={disablePopUp} />
            
            {
                type === 'alert' ? 
                    <Alert { ...alert } disablePopUp={disablePopUp} />
                : <></>
            }
        </div>
    )
}

PopUp.defaultProps = {
    disableLength: 0,
    disableTimer: false
}

export default PopUp;
