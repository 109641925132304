import React from 'react';

export type PopUpAlertProps = {
    title: string;
    titleColor?: string;
    backgroundColor?: string;
}

export const Alert: React.FC<PopUpAlertProps & { disablePopUp: () => void } > = ({ title, titleColor, backgroundColor, disablePopUp }) => {

    return (
        <div className='popup-content alert-container' style={{ backgroundColor }}>
            <div className='title' style={{ color: titleColor }}>{title}</div>
            <div className='button' onClick={disablePopUp}>Okay</div>
        </div>
    )
}