import React from 'react';
import Logo from '../../../Components/Logo';

import { EventWrapperProps } from './wrapper';

export const EventReviewWelcome: React.FC<EventWrapperProps & { banner: string, title: string }> = ({ forward, banner, title }) => {

    return (
        <div className='event-wrapper'>
            <Logo />
            
            <div className='logo ticket-review'>Ticket Review</div>
            
            <div className='banner-wrapper'>
                <img src={banner} alt='banner' className='banner' />
            </div>

            <div className='title'>{title}</div>

            <div className='desc'>
                This is your time to let us know what you thought. Thank you for attending {title}!!! We are excited to know your thoughts!
            </div>

            <div className='button center' onClick={forward} >Review Now</div>
        </div>
    )
}