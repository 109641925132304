import React from 'react';

type CalenderProps = {
    fill?: string;
    stopColor1?: string;
    stopColor2?: string;
}

const Calender: React.FC<CalenderProps> = ({ fill, stopColor1, stopColor2 }) => {
    return (
        <svg width="212" height="187" viewBox="0 0 212 187" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61.0297 0C66.3517 0 70.6661 3.80562 70.6661 8.5V11.3724C75.4654 11.3332 80.5795 11.3332 86.0221 11.3333H125.977C131.418 11.3332 136.533 11.3332 141.332 11.3724V8.5C141.332 3.80562 145.647 0 150.969 0C156.291 0 160.605 3.80562 160.605 8.5V11.9314C163.52 12.1122 166.268 12.3474 168.851 12.654C180.416 14.0255 190.154 16.9597 197.888 23.7813C205.621 30.6031 208.948 39.1922 210.502 49.3935C210.851 51.673 211.117 54.0959 211.322 56.6667H0.677246C0.882051 54.0959 1.14892 51.673 1.49634 49.3935C3.05127 39.1922 6.37762 30.6031 14.1114 23.7813C21.8451 16.9597 31.5827 14.0255 43.1478 12.654C45.7318 12.3474 48.4787 12.1122 51.3933 11.9314V8.5C51.3933 3.80562 55.7076 0 61.0297 0Z" fill={fill}/>
            <path d="M61.0297 0C66.3517 0 70.6661 3.80562 70.6661 8.5V11.3724C75.4654 11.3332 80.5795 11.3332 86.0221 11.3333H125.977C131.418 11.3332 136.533 11.3332 141.332 11.3724V8.5C141.332 3.80562 145.647 0 150.969 0C156.291 0 160.605 3.80562 160.605 8.5V11.9314C163.52 12.1122 166.268 12.3474 168.851 12.654C180.416 14.0255 190.154 16.9597 197.888 23.7813C205.621 30.6031 208.948 39.1922 210.502 49.3935C210.851 51.673 211.117 54.0959 211.322 56.6667H0.677246C0.882051 54.0959 1.14892 51.673 1.49634 49.3935C3.05127 39.1922 6.37762 30.6031 14.1114 23.7813C21.8451 16.9597 31.5827 14.0255 43.1478 12.654C45.7318 12.3474 48.4787 12.1122 51.3933 11.9314V8.5C51.3933 3.80562 55.7076 0 61.0297 0Z" fill="url(#paint0_linear_105_216)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.27789e-05 87.2089C-0.000115706 82.4104 -0.000113884 77.8997 0.0442135 73.6667H211.956C212 77.8986 212 82.4093 212 87.2078V122.239C212 132.487 212.001 140.752 211.315 147.42C210.61 154.292 209.116 160.296 205.545 165.75C201.316 172.211 195.234 177.576 187.909 181.306C181.726 184.455 174.919 185.773 167.128 186.396C159.57 187 150.2 187 138.582 187H86.0229C68.4516 187 54.2873 187 43.1485 185.68C31.5835 184.308 21.8458 181.374 14.1122 174.553C6.3784 167.73 3.05205 159.142 1.49712 148.94C-0.000499035 139.114 -0.000244191 126.622 1.27789e-05 111.122V87.2089ZM42.3221 135.183C42.3221 130.488 46.6364 126.683 51.9585 126.683H120.562C125.884 126.683 130.199 130.488 130.199 135.183C130.199 139.877 125.884 143.683 120.562 143.683H51.9585C46.6364 143.683 42.3221 139.877 42.3221 135.183ZM51.9585 94.4883C46.6364 94.4883 42.3221 98.2929 42.3221 102.988C42.3221 107.683 46.6364 111.488 51.9585 111.488H90.5044C95.8263 111.488 100.141 107.683 100.141 102.988C100.141 98.2929 95.8263 94.4883 90.5044 94.4883H51.9585Z" fill={fill}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.27789e-05 87.2089C-0.000115706 82.4104 -0.000113884 77.8997 0.0442135 73.6667H211.956C212 77.8986 212 82.4093 212 87.2078V122.239C212 132.487 212.001 140.752 211.315 147.42C210.61 154.292 209.116 160.296 205.545 165.75C201.316 172.211 195.234 177.576 187.909 181.306C181.726 184.455 174.919 185.773 167.128 186.396C159.57 187 150.2 187 138.582 187H86.0229C68.4516 187 54.2873 187 43.1485 185.68C31.5835 184.308 21.8458 181.374 14.1122 174.553C6.3784 167.73 3.05205 159.142 1.49712 148.94C-0.000499035 139.114 -0.000244191 126.622 1.27789e-05 111.122V87.2089ZM42.3221 135.183C42.3221 130.488 46.6364 126.683 51.9585 126.683H120.562C125.884 126.683 130.199 130.488 130.199 135.183C130.199 139.877 125.884 143.683 120.562 143.683H51.9585C46.6364 143.683 42.3221 139.877 42.3221 135.183ZM51.9585 94.4883C46.6364 94.4883 42.3221 98.2929 42.3221 102.988C42.3221 107.683 46.6364 111.488 51.9585 111.488H90.5044C95.8263 111.488 100.141 107.683 100.141 102.988C100.141 98.2929 95.8263 94.4883 90.5044 94.4883H51.9585Z" fill="url(#paint1_linear_105_216)"/>
            <defs>
            <linearGradient id="paint0_linear_105_216" x1="209.335" y1="2.27273" x2="171.095" y2="112.45" gradientUnits="userSpaceOnUse">
            <stop stopColor={stopColor1}/>
            <stop offset="1" stopColor={stopColor2}/>
            </linearGradient>
            <linearGradient id="paint1_linear_105_216" x1="210" y1="78.2122" x2="94.605" y2="245.521" gradientUnits="userSpaceOnUse">
            <stop stopColor={stopColor1}/>
            <stop offset="1" stopColor={stopColor2}/>
            </linearGradient>
            </defs>
        </svg>

    )
}

export default Calender;
