import React from 'react';

type DownArrowProps = {
    fill?: string;
    className?: string;
    onClick?: () => void;
}

const DownArrow: React.FC<DownArrowProps> = ({ fill, className, onClick }) => {

    return (
        <svg fill={fill} version="1.1" onClick={onClick} className={`downArrow ${className}`} id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 481.728 481.728">
            <g>
                <g>
                    <path d="M10.467,146.592l198.857,252.903c17.417,30.532,45.661,30.532,63.085,0l198.839-252.867
                        c3.886-5.532,8.072-15.41,8.923-22.118c2.735-21.738,4.908-65.178-21.444-65.178H23.013c-26.353,0-24.192,43.415-21.463,65.147
                        C2.395,131.188,6.587,141.06,10.467,146.592z"/>
                </g>
            </g>
        </svg>
    )
}

export default DownArrow;
