import Markdown from 'markdown-to-jsx';
import React from 'react';

import EventReviewCard, { EventReviewCardProps } from '../../../Components/EventReviewCard';

type EventReviewCompleteProps = {
    back: () => void;
} & EventReviewCardProps;

export const EventReviewComplete: React.FC<EventReviewCompleteProps> = ({ back, photo, name, rating, description, dateCompleted }) => {
    return (
        <div className='event-card-wrapper'>
            <EventReviewCard photo={photo} name={name} rating={rating} description={description} dateCompleted={dateCompleted}/>
            <div className='button' onClick={back} >Edit</div>
        </div>
    )
}