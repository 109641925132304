import React from 'react';

interface Props {
    setNew: ( text: string ) => void;
    confirmNew: ( text: string ) => void;
    confirm: () => void;
}

export const Verified: React.FC<Props> = ({ setNew, confirmNew, confirm }) => {
    return (
        <div className="UnVerified Verified">
            <div className="image-div">
                <img src="/logo-text.png" alt="text-logo" className="text-logo" />
            </div>

            <div className="verify">
                <input type="password" placeholder='New Password' onChange={ (e) => setNew( e.target.value ) }/>
                <input type="password" placeholder="Confirm Password" onChange={ (e) => confirmNew( e.target.value ) } />

                <div className="confirm-password" onClick={confirm}>Change Password</div>
            </div>

            <div className='claim'>
                &copy;&nbsp;
                <a href='https://www.cravingsinc.us'>www.cravingsinc.us</a>
            </div>
        </div>
    )
}
