import "./index.scss";
import "./mobile.scss";

import React from "react";

import Logo from "../Logo";

import Facebook from "../svgs/Facebook";
import Twitter from "../svgs/Twitter";
import Instagram from "../svgs/Instagram";

export const Footer : React.FC<any> = () => {
    return (
        <div className="footer">
            <div className="top-row">
                <div className='logo-links'>
                    <Logo />
                    <div className="links">
                        <a href='/about'>About</a>
                        <a href='/events'>Events</a>
                        <a href="/contact">Contact</a>
                    </div>
                </div>
                <div className="moto">
                    Creating Moments You Crave, Every Day
                </div>

                <div className='social-links'>
                    <div className='title'>Follow Us On Social Media</div>

                    <div className='links'>
                        <a href="https://www.facebook.com/CravingsInc" target="_blank"> <Facebook stopColor1="#BC29F0" stopColor2="#FF3636"/> </a>
                        <a href="https://x.com/_cravingsinc_" target="_blank"> <Twitter innerFill="white" stopColor1="#E22CFF" stopColor2="#FF133D" /> </a>
                        <a href="https://www.instagram.com/_cravingsinc_/" target="_blank"> <Instagram stopColor1="#DC1DEC" stopColor2="#F62828" /> </a>
                    </div>
                </div>
            </div>

            <div className="bottom-row">
                &#169; CravingsInc 2023
            </div>
        </div>
    )
}

