export const getUserProfileInformation = `
query GetUserProfileInformation($token: String!) {
  getUserProfileInformation(token: $token) {
    id
    firstName
    lastName
    email
    phoneNumber
    username
    profilePicture
    events
    searchMilesRadius
    following
    followers
    notificationsSettings {
      updates
      newFollower
    }
  }
}
`;

export const userFollowingQuery = `
query UsersFollowing($token: String!) {
  usersFollowing(token: $token) {
    id
    objectId
    objectName
    objectPic
    type
  }
}
`;

export const userDeleteUserFollowing = `
mutation UserDeleteUserFollowing($userId: String!, $token: String!) {
  userDeleteUserFollowing(userId: $userId, token: $token) {
    deletedUserFollowing {
      id
      objectId
      objectName
      objectPic
    }
    userFollowing {
      id
      objectId
      objectName
      objectPic
    }
  }
}
`;

export const userDeleteOrgFollowing = `
mutation UserDeleteOrgFollowing($orgId: String!, $token: String!) {
  userDeleteOrgFollowing(orgId: $orgId, token: $token) {
    deletedOrgFollowing {
      id
      objectId
      objectName
      objectPic
      type
    }
    orgFollowing {
      id
      objectId
      objectName
      objectPic
      type
    }
  }
}
`;

export const userOrgFollowingQuery = `
query UsersOrgFollowing($token: String!) {
  usersOrgFollowing(token: $token) {
    id
    objectId
    objectName
    objectPic
    type
  }
}
`;

export const modifyUserProfileInformationQuery = `
mutation ModifyUserProfileInformation($arg: UserProfileInformationInput!, $token: String!) {
  modifyUserProfileInformation(arg: $arg, token: $token)
}
`;

export const changeUserPassword = `
mutation ChangeUserPassword($token: String!) {
  changeUserPassword(token: $token)
}
`;

export const followOrganizerMutation = `
mutation Mutation($organizerId: String!, $token: String!) {
  followOrganizer(organizerId: $organizerId, token: $token)
}
`;

export const unFollowOrganizerMutation = `
mutation UnFollowOrganizer($organizerId: String!, $token: String!) {
  unFollowOrganizer(organizerId: $organizerId, token: $token)
}
`;

export const modifyUserNotifications =  `
mutation ModifyUserNotifications($arg: UserNotificationsInput!, $token: String!) {
  modifyUserNotifications(arg: $arg, token: $token)
}
`;
