import React, { useState, useEffect } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import NavBar from "../../Components/navbar/SignedOut";

import { MakeReservationInput } from "../../utils";
import { useMakeReservation } from "../../hooks";

import "./index.css";

export const Reserve : React.FC<any> = () => {
    const [ props, setProps ] = useState<MakeReservationInput>({ first_name: "", last_name: "", date: "", time: "", event_name: "", email: "", phone_number: "" });
    const { reload, makeReservation } = useMakeReservation();


    useEffect( () => {
        if ( reload === true ) window.location.reload();
    }, [ reload ]);

    return (
        <div className="ReservePage">
            <NavBar />
            
            <div className="content">
                <img src="/reserve-page-food.png" alt="page-food" className="page-food" />

                <div className="form-container">
                    <div className="header">Make A Reservation</div>

                    <div className="sub-header">We will contact you as soon as possible regarding your reservation</div>

                    <div className="link">
                        <div className="label">
                            <label htmlFor="first_name">First Name</label>
                            <input type="text" className="form-control-input" name="first_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, first_name: e.target.value }))}
                            />
                        </div>

                        <div className="label">
                            <label htmlFor="last_name">Last Name</label>
                            <input type="text" className="form-control-input" name="last_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, last_name: e.target.value }))}
                            />
                        </div>
                    </div>

                    <div className="link">
                        <div className="label">
                            <label htmlFor="date">Date</label>
                            <input type="text" className="form-control-input" name="date" 
                                onChange={ (e) => setProps( prev => ({ ...prev, date: e.target.value }))}
                            />
                        </div>

                        <div className="label">
                            <label htmlFor="time">Time</label>
                            <input type="text" className="form-control-input" name="time" 
                                onChange={ (e) => setProps( prev => ({ ...prev, time: e.target.value }))}
                            />
                        </div>
                    </div>

                    <div className="label">
                        <label htmlFor="event_name">Event Name</label>
                        <input type="telle" className="form-control-input" name="event_name" 
                            onChange={ (e) => setProps( prev => ({ ...prev, event_name: e.target.value }))}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control-input" name="email" 
                            onChange={ (e) => setProps( prev => ({ ...prev, email: e.target.value }))}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input type="telle" className="form-control-input" name="phone_number" 
                            onChange={ (e) => setProps( prev => ({ ...prev, phone_number: e.target.value }))}
                        />
                    </div>

                    <div className="submit"
                        onClick={
                            () => {
                                // Veryify Props then send to server

                                if (
                                    props.first_name.length <= 0
                                    || props.last_name.length <= 0
                                    || props.date.length <= 0
                                    || props.time.length <= 0
                                    || props.phone_number.length <= 0
                                    || props.event_name.length <= 3
                                    || !props.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
                                ) return alert("Please fill out form.");

                                makeReservation(props);
                            }
                        }
                    >
                        <div className="btn-text">Reserve Now</div>
                        <BsFillArrowRightCircleFill className="btn-ico" />
                    </div>
                </div>
            </div>
        </div>
    )
}
