import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { fetchGraphQl, verifyUserPasswordChangeToken, confirmChangePassword } from "../utils";

export const useUserPasswordChange = () => {
    let { token } = useParams();
    const [ verified, setVerified ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    
    const [ newPassword, setNewPassword ] = useState("");
    const [ confirmNewPassword, setConfirmNewPassword ] = useState("");

    useEffect( () => {
        (
            async () => {
                let res = await fetchGraphQl( verifyUserPasswordChangeToken, { token });

                setLoading( false );

                if ( res.errors ) return setVerified( false );

                if ( res.data.verifyUserPasswordChangeToken === "Token is valid" ) setVerified( true );
                else setVerified( false );
            }
        )()
    }, [ ]);

    return {
        loading,
        verified,
        setNewPassword,
        setConfirmNewPassword,
        confirm: async () => {
            if ( newPassword.length < 1 || confirmNewPassword.length < 1 ) return alert("Please enter your new password")

            if ( newPassword !== confirmNewPassword ) return alert("Passwords does not match.");

            let res = await fetchGraphQl(confirmChangePassword, { token, confirmNewPassword, newPassword });

            if ( res.errors ) return alert("Problem changing your password. Please try again.");

            alert(res.data.confirmUserPasswordChange);

            window.location.reload();
        }
    }
}

