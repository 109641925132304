import React from 'react';

type TicketProps = {
    fill?: string;
    stroke?: string;
    onClick?: ( e: any ) => void;
}

const Ticket: React.FC<TicketProps> = ({ fill, stroke, onClick }) => {
    return (
        <svg onClick={onClick} width="235" height="221" viewBox="0 0 235 221" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M135.282 52.2006L179.353 93.6463L99.5786 168.668L55.5074 127.223L135.282 52.2006ZM105.855 180.604L192.044 99.5484C193.811 97.887 194.694 95.9197 194.694 93.6463C194.694 91.3729 193.811 89.4055 192.044 87.7442L141.558 40.2653C139.884 38.6914 137.792 37.9044 135.282 37.9044C132.771 37.9044 130.68 38.6914 129.006 40.2653L42.816 121.32C41.0495 122.982 40.1662 124.949 40.1662 127.223C40.1662 129.496 41.0495 131.463 42.816 133.125L93.3027 180.604C94.9763 182.177 97.0682 182.964 99.5786 182.964C102.089 182.964 104.181 182.177 105.855 180.604ZM229.84 97.0564L103.344 216.147C99.9041 219.382 95.6968 221 90.7225 221C85.7483 221 81.541 219.382 78.1009 216.147L60.5282 199.621C65.7349 194.725 68.3383 188.779 68.3383 181.784C68.3383 174.789 65.7349 168.843 60.5282 163.947C55.3215 159.05 48.999 156.602 41.5608 156.602C34.1227 156.602 27.8002 159.05 22.5935 163.947L5.16024 147.421C1.72008 144.186 0 140.229 0 135.551C0 130.873 1.72008 126.917 5.16024 123.681L131.656 4.85282C135.096 1.61761 139.303 0 144.277 0C149.252 0 153.459 1.61761 156.899 4.85282L174.332 21.2475C169.126 26.144 166.522 32.0898 166.522 39.0849C166.522 46.0799 169.126 52.0257 174.332 56.9223C179.539 61.8188 185.862 64.2671 193.3 64.2671C200.738 64.2671 207.06 61.8188 212.267 56.9223L229.84 73.3169C233.28 76.5521 235 80.5087 235 85.1866C235 89.8646 233.28 93.8212 229.84 97.0564Z" fill={stroke} />
        </svg>
    )
}

export default Ticket;
