import './index.scss';

import React from "react";

import Markdown from "markdown-to-jsx";
import Rating from 'react-star-rating-component';

import { formatEventDate, truncateText } from "../../utils";

export type EventReviewCardProps = {
  photo: string;
  name: string;
  rating: number;
  description: string;
  dateCompleted: Date | null;
};

const EventReviewCard: React.FC<EventReviewCardProps> = ({
  photo,
  name,
  rating,
  description,
  dateCompleted,
}) => {
  return (
    <div className="event-review-card">
        <div className="card-header">
            <div className="seperator-np">
                <img src={photo} alt="profile" className="profile-image" />
                <div className="name">{name}</div>
            </div>

            <div className="star-wrapper">
                <Rating name="rate" editing={false} value={rating} />
            </div>
        </div>

        <div className="desc">
            <Markdown>{truncateText(description, 150)}</Markdown>
        </div>

        <div className="date-complete">
            {formatEventDate( dateCompleted ? new Date( dateCompleted ) : new Date() ) }
        </div>
    </div>
  );
};

export default EventReviewCard;
