export const makeReservation = `
    query makeReservation( $reservationInput: ReservationInput! ) {
        makeReservation( reservationInput: $reservationInput )
    }
`

export const makeContact = `
    query makeContact( $contactInput: ContactInput! ) {
        makeContact( contactInput: $contactInput )
    }
`
