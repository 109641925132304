import React from 'react';

type LocationProps = {
    className?: string;
    stopColor1?: string;
    stopColor2?: string;
    onClick?: (e: any) => void;
}

const Location: React.FC<LocationProps> = ({ className, stopColor1, stopColor2, onClick }) => {
    return (
        <svg className={className} onClick={onClick} width="65" height="40" viewBox="0 0 65 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 15C1.2816e-07 11.0218 3.4166 7.20644 9.49819 4.3934C15.5798 1.58035 23.8282 0 32.4289 0C41.0295 0 49.2779 1.58035 55.3595 4.3934C61.4411 7.20644 64.8577 11.0218 64.8577 15C64.8577 25 32.4289 40 32.4289 40C32.4289 40 0 25 0 15ZM18.9168 15C18.9168 16.6576 20.3404 18.2473 22.8744 19.4194C25.4084 20.5915 28.8452 21.25 32.4289 21.25C36.0125 21.25 39.4493 20.5915 41.9833 19.4194C44.5173 18.2473 45.9409 16.6576 45.9409 15C45.9409 13.3424 44.5173 11.7527 41.9833 10.5806C39.4493 9.40848 36.0125 8.75 32.4289 8.75C28.8452 8.75 25.4084 9.40848 22.8744 10.5806C20.3404 11.7527 18.9168 13.3424 18.9168 15Z" fill="url(#paint0_linear_137_276)"/>
            <defs>
            <linearGradient id="paint0_linear_137_276" x1="0" y1="0" x2="46.2425" y2="42.0643" gradientUnits="userSpaceOnUse">
            <stop stopColor={stopColor1} />
            <stop offset="1" stopColor={stopColor2}/>
            </linearGradient>
            </defs>
        </svg>
    );
}

export default Location;
