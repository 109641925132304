import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { isAndroid, isChrome, isChromium, isConsole, isDesktop, isEdge, isEmbedded, isFirefox, isIE, isIOS, isMobile, isMobileSafari, isOpera, isSafari, isSamsungBrowser, isSmartTV, isTablet, isWearable, isWinPhone, isYandex } from 'react-device-detect';

import { LOCAL_STORAGE_NAMESPACES, createSiteHistory, fetchGraphQl } from '../../utils';

export const useSiteHistory = () => {
    const [ lastHistory, setLastHistory ] = useState<string>('');

    const location = useLocation();

    const saveHistory = async () => {

        let arg = {
            token: localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN ),
            craving: localStorage.getItem( LOCAL_STORAGE_NAMESPACES.CRAVING ),
            urlFull: window.location.href,
            isMobile,
            isTablet,
            isDesktop,
            isSmartTV,
            isWearable,
            isConsole,
            isEmbedded,
            isAndroid,
            isIOS,
            isChrome,
            isFireFox: isFirefox,
            isSafari,
            isOpera,
            isIE,
            isEdge,
            isYandex,
            isChromium,
            isMobileSafari,
            isSamsungBrowser,
            isWinPhone
        }

        // so doesn't repeat in situations like re-renders;
        if ( window.location.href === lastHistory ) return;

        const res = await fetchGraphQl( createSiteHistory, { arg } );

        setLastHistory( window.location.href );

        if ( res.errors ) return console.log( res.errors[0].message );
    }

    useEffect( () => {
        process.env.NODE_ENV === "production" && saveHistory();
    }, [ location ]);


}