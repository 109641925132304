import "./index.scss";
import "./mobile.scss";

import React from 'react';
import { useNavigate } from "react-router-dom";

import Location from "../svgs/Location";

import Markdown from 'markdown-to-jsx';

import { isMobile } from 'react-device-detect';

import { EventsCardProps, formatDate, truncateText } from '../../utils';


export const EventsCard: React.FC<EventsCardProps> = ({ id, title, description, banner, costRange, location, organizer, eventDate }) => {
    const navigate = useNavigate();

    const datePassed = new Date().getTime() > new Date(eventDate).getTime();

    const eventToday = new Date().getTime() === new Date(eventDate).getTime();

    return (
        <div className="EventsCard" onClick={() => navigate(`/events/${id}`)}>
            <div className='extra-card'>
                {
                    eventToday && <div className='event-today'>EVENT TODAY</div>
                }

                {
                    datePassed && <div className='event-passed'>EVENT OVER</div>
                }
            </div>

            <img className='banner' src={banner} alt='banner' />
            
            <div className="title">{title}</div>

            <div className="desc">
                <Markdown>{truncateText(description, isMobile ? 50 : undefined )}</Markdown>
            </div>

            <div className="loc-url">
                <div className='loc-C'>
                    <img src={organizer.profilePicture} alt='banner' />
                    <div className="orgName">{organizer.name}</div>
                </div>

                <div className='bullet' />

                <div className="date">{formatDate(new Date(eventDate))}</div>

                <div className='bullet' />

                <div className='cost-range'> {costRange} </div>

                <div className='bullet' />

                <Location className="url-ico" stopColor1="#FF00F3" stopColor2="#7F74FF" onClick={
                    (e) => {
                        e.stopPropagation();
                        window.open(`https://www.google.com/maps?q=${location.latitude},${location.longitude}`, '_blank');
                    }
                } />
            </div>
        </div>
    )
}
