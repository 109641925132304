export const getUpComingEvents = `
query GetUpComingEvents($limit: Float!, $latitude: Float, $longitude: Float, $token: String) {
    getUpComingEvents(limit: $limit, latitude: $latitude, longitude: $longitude, token: $token) {
      banner
      costRange
      description
      eventDate
      id
      location {
        latitude
        location
        longitude
      }
      organizer {
        id
        name
        profilePicture
      }
      title
    }
}
`;

export const getFeaturedEvents = `
query GetFeaturedEvents($limit: Float!, $latitude: Float, $longitude: Float, $token: String) {
  getFeaturedEvents(limit: $limit, latitude: $latitude, longitude: $longitude, token: $token) {
      banner
      costRange
      description
      eventDate
      id
      location {
        latitude
        location
        longitude
      }
      organizer {
        id
        name
        profilePicture
      }
      title
    }
}
`;

export const getPopularEvents = `
query GetPopularEvents($limit: Float!, $latitude: Float, $longitude: Float, $token: String) {
    getPopularEvents(limit: $limit, latitude: $latitude, longitude: $longitude, token: $token) {
      banner
      costRange
      description
      eventDate
      id
      location {
        latitude
        location
        longitude
      }
      organizer {
        id
        name
        profilePicture
      }
      title
    }
}
`;

export const getPopularOrganizersEvents = `
query GetPopularEvents($limit: Float!, $latitude: Float, $longitude: Float, $token: String) {
    getPopularOrganizersEvents(limit: $limit, latitude: $latitude, longitude: $longitude, token: $token) {
      banner
      costRange
      description
      eventDate
      id
      location {
        latitude
        location
        longitude
      }
      organizer {
        id
        name
        profilePicture
      }
      title
    }
}
`;

export const orgFollowingEvents = `
query GetOrgFollowingEvents($token: String!, $limit: Float) {
  getOrgFollowingEvents(token: $token, limit: $limit) {
    id
    eventDate
    description
    costRange
    banner
    location {
      latitude
      location
      longitude
    }
    organizer {
      profilePicture
      name
      id
    }
    title
  }
}
`;

export const friendsFollowingEvents = `
query GetFriendsFollowingEvents($token: String!, $limit: Float!) {
  getFriendsFollowingEvents(token: $token, limit: $limit) {
    banner
    costRange
    description
    eventDate
    id
    organizer {
      id
      name
      profilePicture
    }
    location {
      latitude
      location
      longitude
    }
    title
  }
}
`;

export const getEventPage = `
query GetEventsPage($eventId: String!, $userToken: String) {
  getEventsPage(eventId: $eventId, userToken: $userToken) {
    id
    name
    description
    banner
    costRange
    eventDate
    endEventDate
    ticketType
    ticketAvailable
    userFollowing
    photoGallery {
      id
      picture
      eventId
    }
    location {
      latitude
      location
      longitude
    }
    organizer {
      id
      name
      profilePicture
      followers
      events
    }
    prices {
      id
      title
      description
      amount
      ticketAvailable
    }
    ticketSold
  }
}
`;

export const createTicketSellClientSecret = `
mutation Mutation($eventId: String!, $userToken: String) {
  createTicketSellClientSecret(eventId: $eventId, userToken: $userToken)
}
`;

export const updateTicketSellClientSecret = `
mutation UpdateTicketSellClientSecret($prices: [TicketBuyClientSecretUpdate!]!, $eventId: String!, $id: String!) {
  updateTicketSellClientSecret(prices: $prices, id: $id, eventId: $eventId)
}
`;

export const getTicketBuy = `
query GetTicketBuy($paymentIntent: String!) {
  getTicketBuy(payment_intent: $paymentIntent) {
    id
    name
    banner
    date
    paymentIntent
    buyer {
      name
      email
      admitCount
    }
  }
}
`;

export const getTicketReview = `
query getTicketReview($paymentIntent: String!) {
    getTicketReview(payment_intent: $paymentIntent ) {
        eventId,
        eventBanner,
        eventTitle,
        ratingId,
        ratingName,
        rating,
        photo,
        description,
        reviewCompleted,
        dateReviewCompleted
        payment_intent
    }
}
`;

export const submitTicketReview = `
mutation submitTicketReview ( $paymentIntent: String!, $args: EventTicketReviewInput! ) {
  submitTicketReview( payment_intent: $paymentIntent, args: $args )
}
`;

export const getEventTicketsReviews = `
query GetEventTicketsReviews($eventId: String!) {
  getEventTicketsReviews(event_id: $eventId) {
    name
    photo
    rating
    description
    dateCompleted
  }
}
`;
