import React from 'react';

import { Route, Routes } from "react-router-dom";
import { Home, Events, Reserve, Contact, About, ChangeUserPassword, Register, SignIn, EventPage, EventTicket, UserHome } from './Routes';
import { useSiteHistory } from './hooks';
import EventReview from './Routes/Event/EventReview';

function App() {
  useSiteHistory()

  return (
    <Routes>
      <Route path="/" element={<Events />} />

      <Route path='/about' element={<About />} />

      <Route path="/register" element={<Register />}/>

      <Route path='/sign-up' element={<SignIn />} />

      <Route path="/events">
        <Route path='' element={<Events />} />

        <Route path=':eventId'>
          <Route path='' element={<EventPage />} />

          <Route path='review' element={<EventReview />} />

          <Route path='ticket' element={<EventTicket />}/>
        </Route>

      </Route>

      <Route path='/home'>
        <Route path='' element={<UserHome />} />
      </Route>

      <Route path="/reserve" element={<Reserve />} />

      <Route path="/contact" element={<Contact />} />

      <Route path='/change-password/user/:token' element={<ChangeUserPassword />} />
    
    </Routes>
  );
}

export default App;
