import React, { useState, useEffect } from 'react';

import { useLogin } from '../../session';
import { useLocation } from '../../useLocation';
import { EventTicketReview, fetchGraphQl, getTicketReview } from '../../../utils';

export const useEventReview = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const paymentIntent = searchParams.get('payment_intent');

    const { loading: loginLoading, loggedIn, token, tokenType, relogin, user } = useLogin();

    const [ loading, setLoading ] = useState<boolean>(false);

    const { latitude, longitude } = useLocation(false);

    const [ error, setError ] = useState<string>();

    const [ ticketReview, setTicketReview ] = useState<EventTicketReview>({
        eventId: "",
        eventBanner: "",
        eventTitle: "",
        ratingId: "",
        ratingName: "",
        rating: 0,
        photo: "",
        description: "",
        reviewCompleted: false,
        dateReviewCompleted: null,
        payment_intent: ""
    });

    useEffect( () => {
        relogin( false );

        if ( !paymentIntent ) return setError('No payment intent');

        (
            async () => {
                setLoading( true );

                let res = await fetchGraphQl( getTicketReview, { paymentIntent });

                if ( res.errors ) {
                    console.log( res.errors );
                    return setError( res.errors[0].message );
                }

                let data = res.data.getTicketReview as EventTicketReview;

                setTicketReview( data );

                setLoading( false );
            }
        )()
    }, [ ]);

    return {
        loading: loginLoading || loading,
        token,
        tokenType,
        user,
        ticketReview,
        payment_intent: paymentIntent,
        onChangeName: ( val: string ) => setTicketReview( prev => ({ ...prev, name: val })),
        onChangeDescription: ( val: string ) => setTicketReview( prev => ({ ...prev, description: val })),
        onChangeRating: ( val: number ) => setTicketReview( prev => ({ ...prev, rating: val }) ),
        setProfilePicture: ( val: string ) => setTicketReview( prev => ({ ...prev, photo: val }) ),
        error,
        setError
    }
}