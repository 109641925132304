import React from 'react';

type TwitterProps = {
    fill?: string;
    innerFill?: string;
    stopColor1?: string;
    stopColor2?: string;
}

const Twitter: React.FC<TwitterProps> = ({ fill, innerFill, stopColor1, stopColor2 }) => {
    return (
        <svg width="140" height="112" viewBox="0 0 140 112" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_117_12)">
            <mask id="mask0_117_12" style={{ maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="140" height="112">
            <path d="M140 0H0V112H140V0Z" fill={innerFill}/>
            </mask>
            <g mask="url(#mask0_117_12)">
            <path d="M123.594 0H16.4062C7.34533 0 0 5.87626 0 13.125V98.875C0 106.124 7.34533 112 16.4062 112H123.594C132.655 112 140 106.124 140 98.875V13.125C140 5.87626 132.655 0 123.594 0Z" fill="url(#paint0_linear_117_12)"/>
            <path d="M97.3174 21.875H111.79L80.1718 50.785L117.368 90.125H88.2437L65.4324 66.2655L39.3312 90.125H24.8501L58.6687 59.2025L22.9863 21.875H52.8499L73.4693 43.6835L97.3174 21.875ZM92.2381 83.195H100.257L48.4924 28.441H39.8868L92.2381 83.195Z" fill="white"/>
            </g>
            </g>
            <defs>
            <linearGradient id="paint0_linear_117_12" x1="70" y1="0" x2="70" y2="112" gradientUnits="userSpaceOnUse">
            <stop stopColor={stopColor1}/>
            <stop offset="1" stopColor={stopColor2}/>
            </linearGradient>
            <clipPath id="clip0_117_12">
            <rect width="140" height="112" fill={innerFill}/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default Twitter;
