import React, { useEffect, useState } from "react";

export const useLocation = ( repeat: boolean = true, interval: number = 2 ) => {
    const [ location, setLocation ] = useState<{ latitude: number, longitude: number}>({ latitude: 0, longitude: 0 });

    const requestLocation = () => {
        if ("geolocation" in navigator) {
            // Geolocation is available
            navigator.geolocation.getCurrentPosition(
              (position) => {
                // Get latitude and longitude coordinates from the position object
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
          
                // Use latitude and longitude coordinates
                setLocation({ latitude, longitude });
              },
              (error) => {
                // Handle error
                console.error("Error getting location:", error.message);
              }
            );
        } else {
            // Geolocation is not available
            console.error("Geolocation is not supported");
        }
    }
    
    useEffect( () => {
        if ( !repeat ) return requestLocation();

        const intervalId = setInterval( () => {
            requestLocation();
        }, 1000 * interval);

        return () => clearInterval(intervalId);
    }, [ ]);

    return {
        latitude: location.latitude,
        longitude: location.longitude
    }
}
