import React, { useState, useEffect } from 'react';
import { Craving } from '../../utils';

type ProfileBubbleProps = {
    username: string;
    profilePicture: string;
    onDeleteAction?: ( 
        event: { 
            delete: ( prop: boolean ) => void;

        } 
    ) => Promise<void>;
    onClickAction: () => void;
}

type ProfileBubbleState = {
    disabled: boolean;
}

export const ProfileBubble: React.FC<ProfileBubbleProps> = ({ username, profilePicture, onDeleteAction }) => {
    const [ state, setState ] = useState<ProfileBubbleState>({ disabled: onDeleteAction ? false : true  });
    
    const disable = async () => {
        if ( onDeleteAction ) {
            await onDeleteAction({
                delete: ( prop: boolean ) => {
                    setState( prev => ({ ...prev, disabled: prop }) )
                }
            })
        }
    }

    return (
        <div className='profile-bubble' style={{ display: state.disabled && onDeleteAction ? 'none' : 'flex' }}>
            { !state.disabled && <div className='delete' onClick={disable}>X</div> }
            <img src={profilePicture} alt='profile' />
            <div className='username'>{username}</div>
        </div>
    )
}
