import React from 'react';

type FacebookProps = {
    fill?: string;
    stopColor1?: string;
    stopColor2?: string;
}

const Facebook:React.FC<FacebookProps> = ({ fill, stopColor1, stopColor2 }) => {
    return (
        <svg width="113" height="113" viewBox="0 0 113 113" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M56.01 0C25.125 0 0 25.126 0 56.01C0 86.895 25.125 112.022 56.01 112.022C86.893 112.022 112.02 86.895 112.02 56.01C112.02 25.126 86.893 0 56.01 0ZM71.84 35.358C71.84 35.6232 71.7346 35.8776 71.5471 36.0651C71.3596 36.2526 71.1052 36.358 70.84 36.358L64.473 36.362C60.272 36.362 59.504 37.998 59.504 41.22V47.904H70.424C70.711 47.904 70.985 48.028 71.174 48.244C71.363 48.46 71.451 48.747 71.416 49.032L69.861 61.065C69.8299 61.3061 69.712 61.5276 69.5294 61.6881C69.3469 61.8486 69.1121 61.9371 68.869 61.937H59.504V91.816C59.504 92.0812 59.3986 92.3356 59.2111 92.5231C59.0236 92.7106 58.7692 92.816 58.504 92.816H46.08C45.8148 92.816 45.5604 92.7106 45.3729 92.5231C45.1854 92.3356 45.08 92.0812 45.08 91.816V61.937H35.685C35.4198 61.937 35.1654 61.8316 34.9779 61.6441C34.7904 61.4566 34.685 61.2022 34.685 60.937V48.904C34.685 48.6388 34.7904 48.3844 34.9779 48.1969C35.1654 48.0094 35.4198 47.904 35.685 47.904H45.08V40.029C45.08 29.599 51.392 23.121 61.555 23.121C65.924 23.121 69.785 23.446 70.971 23.603C71.2114 23.6348 71.432 23.7528 71.5919 23.9351C71.7517 24.1174 71.8399 24.3515 71.84 24.594V35.358Z" fill="url(#paint0_linear_117_11)"/>
            <defs>
            <linearGradient id="paint0_linear_117_11" x1="-13.5" y1="4.24768e-07" x2="130.5" y2="99" gradientUnits="userSpaceOnUse">
            <stop stopColor={stopColor1}/>
            <stop offset="1" stopColor={stopColor2}/>
            </linearGradient>
            </defs>
        </svg>
    )
}

export default Facebook;
