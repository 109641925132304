import './index.scss';
import './mobile.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../Logo';
import MenuBar from '../../svgs/Menu';

const NavBar: React.FC = () => {
    const navigate = useNavigate();
    const [ menuClicked, setMenuClicked ] = useState<boolean>(false);

    return (
        <div className={`navbar-unsigned ${ menuClicked ? 'mobile-clicked' : '' }`}>
            <div className='logo-container'> 
                <Logo onClick={() => navigate('/')}  />

                {
                    !menuClicked ? <MenuBar className='menu-bar' onClick={ () => setMenuClicked(true) } /> : 
                    <div className='menu-bar-x' onClick={ () => setMenuClicked(false) }>X</div>
                }
            </div>

            <div className='navbar-unsigned-opt-text-container'>
                <div className='opt-text' onClick={() => navigate('/about')}>About</div>
                <div className='opt-text' onClick={() => navigate('/events')}>Events</div>
                <div className='opt-text' onClick={() => navigate('/contact')}>Contact</div>
            </div>

            <div className='navbar-unsigned-opt-button-container'>
                <div className='opt-button' onClick={() => navigate('/sign-up')}>Sign In</div>
                <div className='opt-button' onClick={() => navigate('/register')}>Register</div>
            </div>
        </div>
    )
}

export default NavBar;
