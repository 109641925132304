import React from 'react';

import { useNavigate } from "react-router-dom";

import { EventsCard } from '../../Components/EventCard';
import { ProfileBubble } from './ProfileBubble';

import { EventsCardProps, UserFollowing } from '../../utils';

type ProfileProps = {
    organizersFollowing: UserFollowing[];
    organizersFollowingEvents: EventsCardProps[];
    userFollowing: UserFollowing[];
    usersFollowingEvents: EventsCardProps[];
    deleteUserFollowing: (id: string) => Promise<void>;
    deleteOrgFollowing: (id: string) => Promise<void>;
}

const Profile: React.FC<ProfileProps> = ({ organizersFollowing, userFollowing, organizersFollowingEvents, usersFollowingEvents, deleteUserFollowing, deleteOrgFollowing }) => {
    const navigate = useNavigate();

    return (
        <div className='profile'>
            <div className='container'>
                <div className='title'>Organizers Following ({organizersFollowing.length})</div>
                <div className='container-events'>
                    {
                        organizersFollowing.map(
                            (e, index) =>
                                <ProfileBubble
                                    username={e.objectName}
                                    profilePicture={e.objectPic}
                                    onDeleteAction={async (event) => {
                                        await deleteOrgFollowing(e.id);
                                        
                                        event.delete(true);
                                    }}
                                    onClickAction={() => {
                                        navigate(`/organizer/${e.objectId}`)
                                    }}
                                />
                        )
                    }
                </div>

                {
                    organizersFollowing.length === 0 && (
                        <div className='no-content'>No Organizer Follower</div>
                    )
                }
            </div>

            {
                organizersFollowing.length !== 0 && (
                    <div className='container'>
                        <div className='container-events'>
                            {
                                organizersFollowingEvents.map((e, index) => <EventsCard key={index} {...e} />)
                            }
                        </div>

                        {
                            organizersFollowingEvents.length === 0 && (
                                <div className='no-content'>No Current Events Available</div>
                            )
                        }
                    </div>
                )
            }

            <div className='container'>
                <div className='title'>User Following ({userFollowing.length})</div>
                <div className='container-events'>
                    {
                        userFollowing.map((e, index) =>
                            <ProfileBubble
                                username={e.objectName}
                                profilePicture={e.objectPic}
                                onDeleteAction={async (event) => {
                                    await deleteUserFollowing(e.id);

                                    event.delete(true);
                                }}
                                onClickAction={() => {
                                    navigate(`/user/${e.objectId}`)
                                }}
                            />
                        )
                    }
                </div>

                {
                    userFollowing.length === 0 && (
                        <div className='no-content'>No User Follower</div>
                    )
                }
            </div>

            {
                userFollowing.length !== 0 && (
                    <div className='container'>
                        <div className='container-events'>
                            {
                                usersFollowingEvents.map((e, index) => <EventsCard key={index} {...e} />)
                            }
                        </div>

                        {
                            usersFollowingEvents.length === 0 && (
                                <div className='no-content'>No Current Events Available</div>
                            )
                        }
                    </div>
                )
            }

        </div>
    )
}

export default Profile;
