import './index.scss';

import React from 'react';

import Ticket from '../../svgs/Ticket';
import Party from '../../svgs/Party';

import { Craving, getOrganizerUrl } from '../../../utils';

type ChoosingProps = {
    stateChange?: ( newState: Craving ) => void;
}

const Choosing: React.FC<ChoosingProps> = ({ stateChange }) => {
    return (
        <div className='banner craving-opt-container'>
            <div className='title'>Which Cravings Are You? 👀🤭</div>

            <div className='craving-opt-buttons'>
                <div className='craving-opt-button'>
                    <Ticket stroke={'white'} onClick={ () => window.location.href = `${getOrganizerUrl()}sign-up` } />
                    <div className='text'>Tickets</div>
                </div>

                <div className='craving-opt-button'>
                    <Party stroke={'white'} fill={'white'} onClick={ stateChange?  () => stateChange('events') : undefined }  />
                    <div className='text'>Events</div>
                </div>
            </div>
        </div>
    )
}

export default Choosing;
