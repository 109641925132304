import React from 'react';

type SearchMileRadiusProps = {
    searchMileRadius: number;
    saveSearchMileRadius: ( val: number ) => void;
}

export const SearchMileRadius: React.FC<SearchMileRadiusProps> = ({ searchMileRadius, saveSearchMileRadius  }) => {
    return (
        <div className='search-mile-radius settings-card'>
            <div className='header'>
                <div className='title'>Search Mile Radius</div>
                
                <div className='miles-container'>
                    <div className='button minus' onClick={ () => saveSearchMileRadius( searchMileRadius - 1 ) }>-</div>
                    <div className='text'>{searchMileRadius}</div>
                    <div className='button plus' onClick={ () => saveSearchMileRadius( searchMileRadius + 1 ) }>+</div>
                </div>
            </div>
        </div>
    )
}
