import React from 'react';

type CheckMarkProps = {
    className?: string;
    fill?: string;
}

const CheckMark: React.FC<CheckMarkProps> = ({ className, fill }) => {
    return (
        <svg fill={ fill || 'white'} version="1.1" className={className} id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="256px" height="256px" viewBox="-20.38 -20.38 119.13 119.13" xmlSpace="preserve" stroke="#000000" stroke-width="7.8369" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.313476"></g>
            <g id="SVGRepo_iconCarrier"> 
                <g> 
                    <path d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z"></path> 
                </g> 
            </g>
        </svg>
    )
}

export default CheckMark;
