import React from 'react';

export const UnVerified = () => {
    return (
        <div className="UnVerified">
            <div className="image-div">
                <img src="/logo-text.png" alt="text-logo" className="text-logo" />
            </div>

            <div className="unverify">
                Token can no longer be verified please try requesting another password change.
            </div>
            
            <div className='claim'>
                &copy;&nbsp;
                <a href='https://www.cravingsinc.us'>www.cravingsinc.us</a>
            </div>
        </div>
    )
}
