import React, { useRef } from "react";
import { getImageUploadApi } from "../../../utils";

type PublicInfoProps = {
    token: string;

    username: string;
    setUserName: ( val : string ) => void;
    
    email: string;
    setEmail: ( val : string ) => void;

    profilePicture: string;
    setProfilePicture: ( val : string ) => void;

    saveAllChanges: () => void;
    saveChangesText: string;

    alertPopUp: ( val: string ) => void;
};

export const PublicInfo: React.FC<PublicInfoProps> = ({ token, username, setUserName, email, setEmail, profilePicture, setProfilePicture, saveAllChanges, saveChangesText, alertPopUp }) => {
    const imageInputRef = useRef<HTMLInputElement>(null);

    const handleInputImageChange = async () => {
        if ( imageInputRef.current ) {
            const form = new FormData();
            const image = imageInputRef.current.files![0];

            if (image) {
                form.append("image", image);
                form.append("token", token);
                form.append('craving', 'events');

                const req = await fetch(getImageUploadApi(), {
                    method: "POST",
                    body: form
                });

                let result = await req.json();

                if ( result.error ) {
                    console.log('Error uploading: ' + result.error );
                }

                if (result.url ) {
                    setProfilePicture(result.url);
                }

                alertPopUp( result.error || 'Successfully uploaded new profile picture' );
            }
        }
    }
    
    return (
        <div className="public-info settings-card">
            <div className="header">
                <div className="title">Public Info</div>
            </div>

            <div className='seperator' />

            <div className='content'>
                <div className='container'>
                    <div className="input-container">
                        <div className='title-input'>User Name</div>
                        <input 
                            className='input' 
                            type='text' 
                            placeholder={username} 
                            onChange={ (e) => setUserName( e.target.value ) }
                        />
                    </div>

                    <div className="input-container">
                        <div className='title-input'>Email</div>
                        <input 
                            className='input' 
                            type='text' 
                            placeholder={email} 
                            onChange={ (e) => setEmail( e.target.value ) }
                        />
                    </div>
                </div>

                <div className='container'>
                    <div className='profile-container'>
                        <img className="profile" alt='profile' src={profilePicture} />
                        <div className='upload-button' onClick={ () => imageInputRef.current?.click() }>Upload Profile</div>
                        <input type="file" accept=".png, .jpeg, .jpg, .svg" ref={imageInputRef} name="image" style={{display: "none" }} onChange={handleInputImageChange} title="imageInput"/>
                    </div>

                    <div className='save-changes' onClick={ saveAllChanges }>{saveChangesText}</div>
                </div>
            </div>
        </div>
    )
}
