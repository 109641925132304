import React from 'react';

import Markdown from 'markdown-to-jsx';

import Calender from '../../../Components/svgs/Calender';
import Location from '../../../Components/svgs/Location';
import { formatEventDate, truncateText } from '../../../utils';
import PurchaseEventTicket from '../../../Components/PurchaseEventTicket';
import CreateAccount from '../../../Components/Session/CreateAccount';

import { Craving, EventPage } from '../../../utils';


type EventInfoProps = {
    pageState: EventPage;
    followButton: () => void;
    descClicked: boolean;
    setDescClicked: React.Dispatch<React.SetStateAction<boolean>>;
    datePassed: boolean;
    setCheckOutClickCount: React.Dispatch<React.SetStateAction<number>>;
    setCheckOutActive: React.Dispatch<React.SetStateAction<boolean>>;
    followOrganizer: () => Promise<void>;
    checkOutClickCount: number;
    displaySignUp: boolean;
    checkOutActive: boolean;
    token: string | null;
    tokenType: Craving | null;
}

export const EventInfo: React.FC<EventInfoProps> = ({ pageState, token, tokenType, followButton, descClicked, displaySignUp, followOrganizer, setDescClicked, datePassed, checkOutClickCount, checkOutActive, setCheckOutClickCount, setCheckOutActive }) => {
    return (
        <div className='event-info'>
            <div className='content'>
                <div className='content-container'>
                    <div className='container org'>
                        <img src={pageState.organizer.profilePicture} alt='organizer profile' />

                        <div className='details'>
                            <div className='title'>{pageState.organizer.name}</div>

                            <div className='small-stats'>
                                <div className='stats'>{pageState.organizer.events} events</div>

                                <div className='bullet' />

                                <div className='stats'>{pageState.organizer.followers} followers</div>
                            </div>
                        </div>

                        <div className='follow' onClick={followButton}>{pageState.userFollowing ? "UnFollow" : "Follow"}</div>
                    </div>

                    <div className='container date'>
                        <Calender fill="white" stopColor1="white" stopColor2="white" />

                        <div className='date'>{formatEventDate(new Date(pageState.eventDate), new Date(pageState.endEventDate))}</div>
                    </div>

                    <div className='container loc' onClick={
                        (e) => {
                            e.stopPropagation();
                            window.open(`https://www.google.com/maps?q=${pageState.location.latitude},${pageState.location.longitude}`, '_blank');
                        }
                    }>
                        <Location className="loc-ico" stopColor1="white" stopColor2="white" />

                        <div className='loc-text'>{pageState.location.location}</div>
                    </div>

                    <div className='container desc'>
                        <Markdown>
                            {pageState.description}
                        </Markdown>
                    </div>

                    <div className={`container desc mobile ${descClicked ? 'zoom' : ''}`} onClick={() => setDescClicked(!descClicked)}><Markdown>{descClicked ? pageState.description : truncateText(pageState.description)}</Markdown></div>

                </div>

                {
                    datePassed ? undefined :
                        <div className='price-badge' onClick={
                            pageState.ticketAvailable > 0 || pageState.ticketType === 'infinite' ?
                                () => { setCheckOutActive(true); setCheckOutClickCount(prev => prev + 1) }
                                :
                                undefined
                        }>
                            <div className='range'>{pageState.costRange}</div>
                            {
                                pageState.ticketAvailable > 0 || pageState.ticketType === 'infinite' ?
                                    <div className='button'>GET TICKETS</div>
                                    :
                                    <div className='button'>SOLD OUT</div>
                            }
                        </div>
                }
            </div>

            {
                displaySignUp && (
                    <div className='signup-container'>
                        <div className='wrapper'>
                            <CreateAccount craving={'events'} nav={false} afterEffect={followOrganizer} />
                        </div>
                    </div>
                )
            }

            <PurchaseEventTicket eventId={pageState.id} ticketType={pageState.ticketType} clickCount={checkOutClickCount} active={checkOutActive} disable={() => setCheckOutActive(false)} tickets={pageState.prices} userToken={token && tokenType === 'events' ? token : undefined} />
        </div>
    )
}