import React, { useState, useEffect } from 'react';

import { useLogin } from '../../session';
import { useLocation } from '../../useLocation';
import { EventTicket, fetchGraphQl, getTicketBuy } from '../../../utils';

export const useEventTickets = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const paymentIntent = searchParams.get('payment_intent');

    const { loading: loginLoading, loggedIn, token, tokenType, relogin, user } = useLogin();

    const [ loading, setLoading ] = useState<boolean>(false);

    const { latitude, longitude } = useLocation(false);

    const [ error, setError ] = useState<string>();

    const [ ticket, setTicket ] = useState<EventTicket>();

    useEffect( () => {
        relogin( false );

        if ( !paymentIntent ) return setError('No payment intent')

        const getInfo = async () => {
            setLoading( true );

            let res = await fetchGraphQl( getTicketBuy, { paymentIntent } );

            if ( res.errors ) {
                console.log( res.errors );
                return setError( res.errors[0].message );
            }

            let data = res.data.getTicketBuy as EventTicket;

            setTicket( data );

            setLoading( false );
        }

        getInfo();
    }, [ ])

    return {
        loading: loginLoading || loading,
        token,
        tokenType,
        loggedIn,
        user,
        ticket,
        error
    }
}
