import './index.scss';
import './mobile.scss';

import React, { useState, useEffect } from 'react';

import SignedOutNavBar from "../../../Components/navbar/SignedOut";
import SignedInNavBar from "../../../Components/navbar/SignedIn";

import { useEventPage } from '../../../hooks';
import { EventInfo } from './EventInfo';
import { PhotoGalleryView } from './PhotoGallery';
import { ReviewView } from './Reviews';

export const EventPage: React.FC = () => {
    const { user, token, tokenType, pageState, reviews, eventTicketReviewLoad, followOrganizer, unFollowOrganizer } = useEventPage();
    const [ checkOutActive, setCheckOutActive ] = useState<boolean>(false);
    const [ checkOutClickCount, setCheckOutClickCount ] = useState<number>(0);
    const [ descClicked, setDescClicked ] = useState<boolean>(false);

    const [ displaySignUp, setDisplaySignUp ] = useState<boolean>(false);

    let paramView = ( new URLSearchParams( window.location.search ) ).get("view");

    const [ view, setView ] = useState<"event"|"gallery"|"review">(
        paramView === "gallery" || paramView === "event" || paramView === "review" ? paramView : "event"
    );

    const followButton = async () => {
        // Meaning user does not exist
        if ( pageState === undefined ) return setDisplaySignUp( true );

        else if ( !pageState.userFollowing ) return await followOrganizer();

        else await unFollowOrganizer();
        
    }
    
    const datePassed = new Date().getTime() > new Date( pageState?.eventDate || 0 ).getTime();

    useEffect(() => {
        if ( view === 'review' ) eventTicketReviewLoad();
    }, [ view ])

    return (
        <div className='event-page'>
            {
                pageState === undefined ? (
                    <div>
                        <SignedOutNavBar />
                    </div>
                ) : (
                    <div className={`event-page-container ${ user ? 'signed' : 'unsigned' }`}>
                        <div className='header'>
                            {
                                user ? <SignedInNavBar profilePicture={user.profilePicture} /> 
                                : <SignedOutNavBar />
                            }
                            
                            <img src={pageState.banner} alt='banner' className='banner' />
                            
                            <div className='title-stats'>
                                <div className='title'>{ pageState.name }</div>

                                <div className='small-stats'>
                                    <div className='stats' onClick={ () => setView( "event" )}>Event Info</div>
                                    
                                    <div className='stats' onClick={ () => setView( "gallery" )}>Gallery</div>

                                    <div className='stats' onClick={ () => setView( "review" )}>Reviews</div>
                                </div>
                            </div>
                        </div>

                        <div className='seperator' />

                        {
                            view === "event" ?
                                <EventInfo
                                    token={token}
                                    tokenType={tokenType}
                                    pageState={pageState}
                                    checkOutActive={checkOutActive}
                                    setCheckOutActive={setCheckOutActive}
                                    checkOutClickCount={checkOutClickCount}
                                    setCheckOutClickCount={setCheckOutClickCount}
                                    descClicked={descClicked}
                                    setDescClicked={setDescClicked}
                                    displaySignUp={displaySignUp}
                                    followButton={followButton}
                                    datePassed={datePassed}
                                    followOrganizer={followOrganizer}
                                /> : 
                                    view === 'gallery' ?
                                        <PhotoGalleryView gallery={pageState.photoGallery} />
                                        : <ReviewView reviews={reviews}/>

                        }
                    </div>
                )
            }

        </div>
    )
}
