import React, { useEffect, useState } from "react";

import { useLogin } from "../../session";
import { useLocation } from "../../useLocation";

import { fetchGraphQl, friendsFollowingEvents, getPopularEvents, getPopularOrganizersEvents, getFeaturedEvents, getUpComingEvents, orgFollowingEvents, EventsCardProps } from "../../../utils";

export const useEvents = ( nav: boolean = false ) => {
    const { loading: loginLoading, loggedIn, token, tokenType, relogin, user } = useLogin();
    const [ loading, setLoading ] = useState<boolean>(false);
    const { latitude, longitude } = useLocation(false);

    const [ upComingEvents, setUpComingEvents ] = useState<EventsCardProps[]>([]);

    const [ featuredEvents, setFeaturedEvents ] = useState<EventsCardProps[]>([]);
    const [ currentFeaturedIndex, setCurrentFeaturedIndex ] = useState<number>(0);

    const [ popularEvents, setPopularEvents ] = useState<EventsCardProps[]>([]);
    const [ popularOrganizerEvents, setPopularOrganizerEvents ] = useState<EventsCardProps[]>([]);
    const [ favoriteOrganizerEvents, setFavoriteOrganizerEvents ] = useState<EventsCardProps[]>([]);
    const [ eventsFriendsGoingTo, setEventsFriendsGoingTo ] = useState<EventsCardProps[]>([]);

    const requestUpComingEvents = async () => {
        setLoading( true );

        let res = await fetchGraphQl( getUpComingEvents, { token, latitude, longitude, limit: 20 });

        if ( res.errors ) {
            return console.log( res.errors );
        }

        let data: EventsCardProps[] = res.data.getUpComingEvents;

        setUpComingEvents( data );

        setLoading( false );
    }

    const requestFeaturedEvents = async () => {
        setLoading( true );

        let res = await fetchGraphQl( getFeaturedEvents, { token, latitude, longitude, limit: 20 });

        if ( res.errors ) {
            return console.log( res.errors );
        }

        let data: EventsCardProps[] = res.data.getFeaturedEvents;

        setFeaturedEvents( data );

        setLoading( false );
    }

    const requestPopularEvents = async ( ) => {
        setLoading( true );

        let res = await fetchGraphQl( getPopularEvents, { token, latitude, longitude, limit: 20 });

        if ( res.errors ) return console.log( res.errors );

        let data: EventsCardProps[] = res.data.getPopularEvents;

        setPopularEvents( data );

        setLoading( false );
    }

    const requestPopularOrganizersEvents = async () => {
        setLoading( true );

        let res = await fetchGraphQl( getPopularOrganizersEvents, { token, latitude, longitude, limit: 20 } );

        if ( res.errors ) return console.log( res.errors );

        let data: EventsCardProps[] = res.data.getPopularOrganizersEvents;

        setPopularOrganizerEvents( data );

        setLoading( false );
    }

    const requestOrgFollowingEvents = async () => {
        setLoading( true );

        let res = await fetchGraphQl( orgFollowingEvents, { token, limit: 20 });

        if ( res.errors ) return console.log( res.errors );

        let data: EventsCardProps[] = res.data.getOrgFollowingEvents;

        setFavoriteOrganizerEvents( data );

        setLoading( false );
    }

    const requestFriendsFollowingEvents = async () => {
        setLoading( true );

        let res = await fetchGraphQl( friendsFollowingEvents, { token, limit: 20 });

        if ( res.errors ) return console.log( res.errors );

        let data: EventsCardProps[] = res.data.getFriendsFollowingEvents;

        setEventsFriendsGoingTo( data );

        setLoading( false );
    }

    useEffect( () => {
        relogin(nav);
    }, [ ])

    useEffect( () => {
        
        requestUpComingEvents();

        requestPopularEvents();

        requestPopularOrganizersEvents();

        requestFeaturedEvents();

        // This is for logged in users only
        if ( !token ) return;

        requestOrgFollowingEvents();

        requestFriendsFollowingEvents();

    }, [ token, loggedIn, latitude, longitude ]);

    return {
        loading: loginLoading || loading,
        token,
        tokenType,
        loggedIn,
        user,

        currentFeaturedEvent: featuredEvents.length === 0 ? undefined : featuredEvents[ currentFeaturedIndex ],
        backFeaturedEvent: () => {
            setCurrentFeaturedIndex( prev => {
                if ( prev - 1 <= 0 ) return 0;
                return prev - 1;
            });
        },
        forwardFeaturedEvent: () => {
            setCurrentFeaturedIndex( prev => {
                if ( prev + 1 >= ( featuredEvents.length - 1 ) ) return featuredEvents.length - 1;
                return prev + 1;
            });
        },

        upComingEvents,
        popularEvents,
        popularOrganizerEvents,
        favoriteOrganizerEvents,
        eventsFriendsGoingTo
    }
}
