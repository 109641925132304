import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { EventReviewCardProps } from '../../../Components/EventReviewCard';

import { useLogin } from '../../session';

import { fetchGraphQl, getEventPage, getEventTicketsReviews, EventPage, LOCAL_STORAGE_NAMESPACES, unFollowOrganizerMutation, followOrganizerMutation } from '../../../utils';


export const useEventPage = ( ) => {
    const { eventId } = useParams();
    const { loading: loginLoading, loggedIn, token, tokenType, relogin, user } = useLogin();
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ pageState, setPageState ] = useState<EventPage>();
    const [ error, setError ] = useState<string>();

    const [ reviews, setReviews ] = useState<EventReviewCardProps[]>([]);

    const requestPageState = async () => {
        setLoading( true );

        // Only user token is allowed to be sent to server. Organizer looking at another organizer event will be treated like a guest.
        let res = await fetchGraphQl( getEventPage, { eventId, token: ( tokenType === 'events' && loggedIn ) ? token : undefined  });
    
        if ( res.errors ) {
            setError( res.errors[0].message );
            return console.log( res.errors );
        }

        let data: EventPage = res.data.getEventsPage;

        setPageState(data);

        setLoading( false );
    }

    useEffect( () => {
        relogin(false);
    }, [ ]);

    useEffect( () => {
        requestPageState();
    }, [ token, loggedIn ]);

    return {
        loading: loginLoading || loading,
        token,
        tokenType,
        loggedIn,
        user,
        pageState,
        followOrganizer: async () => {
            let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
            
            setLoading( true );

            if ( !token || !pageState ) {
                setLoading( false );
                
                return setError(' Invalid Token or Organizer ID ');
            }

            let res = await fetchGraphQl( followOrganizerMutation, { organizerId: pageState.organizer.id, token });

            if ( res.errors ) {
                setLoading( false );
                setError( res.errors[0].message );
                return console.log( res.errors );
            }
            
            setPageState( prev => ({
                ...( prev as EventPage ), 
                userFollowing: true, 
                organizer: { 
                    ...( prev as EventPage ).organizer, 
                    followers: ( prev as EventPage ).organizer.followers + 1 
                } 
            }));

            setLoading( false );
        },

        unFollowOrganizer: async () => {
            let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
            
            setLoading( true );

            if ( !token || !pageState ) {
                setLoading( false );
                
                return setError(' Invalid Token or Organizer ID ');
            }

            let res = await fetchGraphQl( unFollowOrganizerMutation, { organizerId: pageState.organizer.id, token });

            if ( res.errors ) {
                setLoading( false );
                setError( res.errors[0].message );
                return console.log( res.errors );
            }

            setPageState( prev => ({
                ...( prev as EventPage ), 
                userFollowing: false, 
                organizer: { 
                    ...( prev as EventPage ).organizer, 
                    followers: ( prev as EventPage ).organizer.followers - 1 
                } 
            }));

            setLoading( false );
        },

        reviews,

        eventTicketReviewLoad: async () => {
            setLoading( true );

            let res = await fetchGraphQl( getEventTicketsReviews, { eventId })

            if ( res.errors ) {
                setLoading( false );
                setError( res.errors[0].message );
                return console.log( res.errors );
            }

            setReviews( res.data.getEventTicketsReviews );
            setLoading( false );

            
        },

        error
    }
}
