import './index.scss';
import './mobile.scss';

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Logo from '../../Logo';
import Search from '../../svgs/Search';
import DownArrow from '../../svgs/DownArrow';

const Navbar: React.FC<{ profilePicture: string }> = ({ profilePicture }) => {
    const navigate = useNavigate();

    const [ menuClicked, setMenuClicked ] = useState<boolean>(false);
    const [ searchedClicked, setSearchClicked ] = useState<boolean>(false);


    const searchClicked = () => {
        setMenuClicked( false );
        setSearchClicked( !searchedClicked );
    }
    
    return (
        <div className={`navbar-signed ${ searchedClicked ? 'searchClicked' : '' }`}> 
            <Logo onClick={() => navigate('/')} />

            <div className='navbar-signed-opt-text-container'>
                <input type='text' placeholder='Search Event'/>
                <div className='opt-button' onClick={() => navigate('/events')}>More Events</div>
            </div>

            <div className='menu'>
                <img onClick={ () => navigate('/home') } src={profilePicture} alt='profile' />
                <DownArrow onClick={ () => setMenuClicked( !menuClicked ) } className={ menuClicked ? 'clicked' : ''}/>

                
                {
                    menuClicked && (
                        <div className='menu-content'>
                            <div className='item' onClick={ searchClicked }>{ searchedClicked ? 'UnSearch' : 'Search' }</div>
                            <div className='item' onClick={() => navigate('/events')}>More Events</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Navbar;
