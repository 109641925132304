import './index.scss'
import React, { useState, useEffect } from 'react';

import CheckMark from '../svgs/CheckMark';

type CheckBoxPrps = {
    val: boolean;
    onChange?: ( val: boolean ) => void;
}

const CheckBox: React.FC<CheckBoxPrps> =  ({ onChange, val }) => {
    console.log( val )
    return (
        <div className={`checkbox ${ val ? 'checked' : '' }`} onClick={ () => onChange && onChange(!val) }>
            {
                val && <CheckMark className='checkmark' fill='white' />
            }
        </div>
    )
}

export default CheckBox;
