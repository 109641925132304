import './index.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Craving, getOrganizerUrl } from '../../utils';
import NavBar from '../../Components/navbar/SignedOut';
import Choosing from '../../Components/Session/Choosing';
import Login from '../../Components/Session/Login';

export const SignIn: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [ craving, setCraving ] = useState<Craving>( searchParams.get('craving') as Craving );

    useEffect( () => {
        if ( craving === 'ticket' ) window.location.href = `${getOrganizerUrl()}sign-up`
    }, [ craving ]);

    return (
        <div className='sign-in'>
            <NavBar />

            {
                craving === null ? <Choosing stateChange={ ( c ) => setCraving( c ) }/> : <Login craving={craving} />
            }
        </div>
    )
}
