import React, { useEffect, useState, Dispatch } from "react";

import { AlertProps } from "../../../Components/PopUp";

import { useLocation } from "../../useLocation";

import { UserFollowing, UserProfileInformation, fetchGraphQl, getUserProfileInformation, userDeleteOrgFollowing, userDeleteUserFollowing, userFollowingQuery, userOrgFollowingQuery } from "../../../utils";
import { useEvents } from "../events";

type Props = { 
    setPopUp: Dispatch<React.SetStateAction<{
        activate: boolean;
        disablePopUp: () => void;
        alert: AlertProps;
    }>>
}

export const useProfileHome = ({ setPopUp } : Props) => {
    const { loading: loginLoading, loggedIn, token, tokenType, favoriteOrganizerEvents, eventsFriendsGoingTo } = useEvents(true);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const { latitude, longitude } = useLocation(false);
    const [ userFollowing, setUserFollowing ] = useState<UserFollowing[]>([]);

    const [ orgFollowing, setOrgFollowing ] = useState<UserFollowing[]>([]);

    const [ userInfor, setUserInfor ] = useState<{ profile: UserProfileInformation }>({ 
        profile: {
            id: '',
            username: '',
            profilePicture: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            following: 0,
            followers: 0,
            events: 0,
            searchMilesRadius: 12,
        }
    });

    const urlParams = new URLSearchParams( window.location.search );
    const [ slide, setSlide ] = useState<'profile' | 'settings' | null>( ( urlParams.get('slide') as 'profile' | 'settings' | null ) || 'settings' ); //change back to profile

    useEffect( () => {
        const getProfile = async () => {
            const res = await fetchGraphQl(getUserProfileInformation, { token });

            if ( res.errors ) {
                console.log( res.errors );
                return setError( res.errors[0].message );
            }

            let data = res.data.getUserProfileInformation as UserProfileInformation;

            setUserInfor( prev => ({ ...prev, profile: data }) )
        }

        const getUserFollowing = async () => {
            const res = await fetchGraphQl(userFollowingQuery, { token });

            if ( res.errors ) {
                console.log( res.errors );
                return setError( res.errors[0].message );
            }

            let data = res.data.usersFollowing as UserFollowing[];

            setUserFollowing([...data]);
        }

        const getOrgFollowing = async () => {
            const res = await fetchGraphQl(userOrgFollowingQuery, { token });

            if ( res.errors ) {
                console.log( res.errors );
                return setError( res.errors[0].message );
            }

            let data = res.data.usersOrgFollowing as UserFollowing[];

            setOrgFollowing([...data]);
        }
        
        const loadData = async () => {
            setLoading(true);

            await getProfile();

            await getUserFollowing();

            await getOrgFollowing();

            setLoading( false );
        }

        loadData();
    }, [ token ]);

    return {
        loading: loginLoading || loading,
        token,
        tokenType,
        loggedIn,
        user: userInfor,
        slide,
        setSlide,
        favoriteOrganizerEvents,
        eventsFriendsGoingTo,
        userFollowing,
        deleteUserFollowing: async ( id: string ) => {
            const res = await fetchGraphQl( userDeleteUserFollowing, { token, userId: id });

            if ( res.errors ) {
                console.log( res.errors );
                setError( res.errors[0].message );
            }

            let newFollowing = res.data.userDeleteUserFollowing.userFollowing;
            let deletedFollowing = res.data.userDeleteUserFollowing.deletedUserFollowing;
            
            setUserFollowing([...newFollowing]);

            setPopUp( prev => ({
                ...prev, 
                activate: true,
                alert: { 
                    ...prev.alert,
                    alert: {
                        ...prev.alert.alert,
                        title: `Successfully unfollowed ${deletedFollowing.objectName}`
                    }
                }
            }));
        },
        
        orgFollowing,

        deleteOrgFollowing: async ( id: string ) => {
            const res = await fetchGraphQl( userDeleteOrgFollowing, { token, orgId: id });
            
            if ( res.errors ) {
                console.log( res.errors );
                setError( res.errors[0].message );
            }

            let newFollowing = res.data.userDeleteOrgFollowing.orgFollowing;
            let deletedFollowing = res.data.userDeleteOrgFollowing.deletedOrgFollowing;

            setOrgFollowing([...newFollowing]);

            setPopUp( prev => ({
                ...prev, 
                activate: true,
                alert: { 
                    ...prev.alert,
                    alert: {
                        ...prev.alert.alert,
                        title: `Successfully unfollowed ${deletedFollowing.objectName}`
                    }
                }
            }));
        }
    }
}