import React from "react";
import CheckBox from "../../../Components/CheckBox";

type NotificationProps = {
    updates: boolean;
    setUpdates: ( val: boolean ) => void;

    newFollower: boolean;
    setNewFollower: ( val: boolean ) => void;

    save: () => Promise<void>;
    saveText: string;
};

export const Notifications: React.FC<NotificationProps> = ({ updates, setUpdates, newFollower, setNewFollower, save, saveText }) => {
    
    return (
        <div className="notifications settings-card">
            <div className="header">
                <div className="title">Notification Type</div>
                <div className="save-changes" onClick={save}>{saveText}</div>
            </div>

            <div className="seperator" />

            <div className="content">
                <div className="notification-container">
                    <div className="updates notification-item">
                       
                        <div className='input-checkbox'>
                            <CheckBox val={updates} onChange={ setUpdates }/>

                            <div className="check-input" >Updates</div>

                        </div>

                        <div className="text-type"> Receive notifications about the new updates on the platform for new releases and bug fixes. </div> 

                        <div className="seperator" />

                    </div>
                    
                    <div className="newFollower notification-item">

                        <div className='input-checkbox'>
                            <CheckBox val={newFollower} onChange={ setNewFollower }/>

                            <div className="check-input" >New Follower</div>
                            
                        </div>

                        <div className="text-type"> Receive notifications when you get a follower. </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};
