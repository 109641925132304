import './index.scss';

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavBar from '../../Components/navbar/SignedOut';

import Choosing from '../../Components/Session/Choosing';
import CreateAccount from '../../Components/Session/CreateAccount';

import { Craving, getOrganizerUrl } from '../../utils';

export const Register: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [ craving, setCraving ] = useState<Craving | null>( searchParams.get('craving') as ( Craving | null ) );

    useEffect( () => {
        if ( craving === 'ticket' ) window.location.href = `${getOrganizerUrl()}register`
    }, [ craving ]);

    return (
        <div className='register'>
            <NavBar />

            {
                craving === null ? <Choosing stateChange={ ( c ) => setCraving( c ) }/> : <CreateAccount craving={craving} />
            }
        </div>
    )
}