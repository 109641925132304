import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PhotoGallery } from '../../../utils';

type PhotoGalleryType = {
    gallery: PhotoGallery[];
}

export const PhotoGalleryView : React.FC<PhotoGalleryType> = ({ gallery }) => {
    const navigate = useNavigate();

    return gallery.length > 0 ? (
        <div className='photo-gallery'>
            {
                gallery.map( photo => (
                    <div className='photo'>
                        <div className='card' onClick={ () => { navigate(`/events/${photo.eventId}`)} }>Go To Event</div>
                        <img src={photo.picture} alt='gallery for event' />
                    </div>
                ))
            }
        </div>
    ) : <div className='empty'>No Pictures Has Been Posted For Event</div>
}
