import React from 'react';

type RequestPasswordChangeProps = {
    request: () => void
    requestText: string
}

export const RequestPasswordChange: React.FC<RequestPasswordChangeProps> = ({ request, requestText }) => {
    return (
        <div className='request-password-change settings-card'>
            <div className='header'>
                <div className='title'>Request Password Change</div>
                <div className='button' onClick={request}>{requestText}</div>
            </div>

            <div className='content'>
                Password change will be sent to your current email saved on the platform.
                Which will include the link you will need to go to in order to change your password.
            </div>
        </div>
    )
}
