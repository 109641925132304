export const createUserAccount = `
mutation CreateUserAccount($password: String!, $email: String!, $username: String!) {
    CreateUserAccount(password: $password, email: $email, username: $username)
}
`;

export const createOrgAccount = `
mutation CreateOrganizerAccount($password: String!, $email: String!, $orgName: String!) {
    CreateOrganizerAccount(password: $password, email: $email, orgName: $orgName)
}
`;

export const organizerLogin = `
mutation OrganizerLogIn($password: String!, $username: String!) {
    OrganizerLogIn(password: $password, username: $username)
}
`;

export const userLogin = `
mutation UserLogIn($password: String!, $username: String!) {
    UserLogIn(password: $password, username: $username)
}
`;

export const reLogin = `
query Query($token: String!) {
    relogin(token: $token)
}
`;
