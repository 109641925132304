import './index.scss';

import React from 'react';

import SignedInNavBar from '../../../Components/navbar/SignedIn';
import SignedOutNavBar from '../../../Components/navbar/SignedOut';

import { useEventTickets } from '../../../hooks';
import EventQrCode from '../../../Components/EventQrCode';
import { formatEventDate, getDomainUrl } from '../../../utils';


export const EventTicket: React.FC = () => {
    const { loading, ticket, error, user } = useEventTickets();

    return (
        <div className='event-ticket'>
            {
                user ? <SignedInNavBar profilePicture={user.profilePicture} /> 
                : <SignedOutNavBar />
            }

            {
                ticket ? (
                    <div className='container'>
                        <div className='event-infor'>
                            <img src={ticket.banner} alt='banner' />
                            <div className='title'>{ticket.name}</div>
                            <div className='date'>{ formatEventDate( new Date( ticket.date ) ) }</div>
                        </div>

                        <div className='seperator' />

                        <div className='ticket-infor'>
                            <div className='infor'>
                                <div className='title'>Name</div>
                                <div className='data'>{ticket.buyer.name}</div>
                            </div>

                            <div className='infor'>
                                <div className='title'>Email</div>
                                <div className='data'>{ticket.buyer.email}</div>
                            </div>

                            <div className='infor'>
                                <div className='title'>Admit Count</div>
                                <div className='data'>{ticket.buyer.admitCount}</div>
                            </div>
                        </div>


                        <EventQrCode data={window.location.href} image="/logo-text.png" display={true} />
                        
                    </div>
                ): <></>
            }
        </div>
    )
}
